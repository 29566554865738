// 
// overlay.scss
// Theme Component

// Bg Dark overlay
// Creates the "bg-dark-overlay-#" variant
@for $i from 2 through 9 {
  $bg-dark-overlay: ($i);
  .bg-dark-overlay-#{($i)} {
    position: relative;
    z-index: 1;
    &:before {
      content: " ";
      background: $black;
      opacity: ($i / 10);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}

// background overlays 
.bg-light-overlay-dotted {
  background-image: radial-gradient($gray-500 10%, transparent 0), radial-gradient($gray-500 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}
.bg-dark-overlay-dotted {
  background-image: radial-gradient($gray-800 10%, transparent 0), radial-gradient($gray-800 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}
.bg-primary-overlay-dotted {
  background-image: radial-gradient($primary 10%, transparent 0), radial-gradient($primary 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

.bg-white-overlay-dotted {
  background-image: radial-gradient($white 10%, transparent 0), radial-gradient($white 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

//pattern overlay
[class*='pattern-overlay-']{
  position: relative;
  z-index: 1;
}
.pattern-overlay-1 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/01.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-2 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/02.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-3 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/03.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-4 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/04.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-5 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/05.png);
    background-position: center center;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-6 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/06.png);
    background-position: center center;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-7 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/07.png);
    background-position: center center;
    background-repeat: repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}

.pattern-overlay-8 {
  &::after {
    content: "";
    background: url(../images/bg/pattern/08.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
  }
}