@charset "UTF-8";
/*!
 * Bootstrap v5.0.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #ea110c;
  --bs-secondary: #d0d4d9;
  --bs-success: #28a745;
  --bs-info: #17a2b8;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f7f8f9;
  --bs-dark: #19191c;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #a1a1a8;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: #a1a1a8;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Spartan", sans-serif;
  font-weight: 700;
  line-height: 1.4;
  color: #19191c;
}

h1, .h1 {
  font-size: calc(2.15rem + 1.8vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.5rem;
  }
}

h2, .h2 {
  font-size: calc(2.0625rem + 0.75vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.625rem;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #ea110c;
  text-decoration: none;
}

a:hover {
  color: #bb0e0a;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(2.675rem + 8.1vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 8.75rem;
  }
}

.display-2 {
  font-size: calc(2.5875rem + 7.05vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 7.875rem;
  }
}

.display-3 {
  font-size: calc(2.4125rem + 4.95vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 6.125rem;
  }
}

.display-4 {
  font-size: calc(2.28125rem + 3.375vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 4.8125rem;
  }
}

.display-5 {
  font-size: calc(2.2rem + 2.4vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 4rem;
  }
}

.display-6 {
  font-size: calc(2.12rem + 1.44vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 3.2rem;
  }
}

.display-7 {
  font-size: calc(2.08rem + 0.96vw);
  font-weight: 700;
  line-height: 1.4;
}

@media (min-width: 1200px) {
  .display-7 {
    font-size: 2.8rem;
  }
}

.display-8 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}

.display-9 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.4;
}

.display-10 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 96%;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 94%;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 94%;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1600px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.7875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #29292e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe0e5;
  appearance: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not(:read-only) {
  cursor: pointer;
}

.form-control:focus {
  color: #29292e;
  background-color: #fff;
  border-color: #ea110c;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.6em;
}

.form-control::placeholder {
  color: #a1a1a8;
  opacity: 1;
}

.form-control:disabled, .form-control:read-only {
  background-color: #f0f1f3;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not(:read-only)::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.4em + 0.8rem + 2px);
  padding: 0.4rem 0.8rem;
  font-size: 0.7875rem;
  border-radius: 0;
}

.form-control-sm::file-selector-button {
  padding: 0.4rem 0.8rem;
  margin: -0.4rem -0.8rem;
  margin-inline-end: 0.8rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.4rem 0.8rem;
  margin: -0.4rem -0.8rem;
  margin-inline-end: 0.8rem;
}

.form-control-lg {
  min-height: calc(1.7em + 1.6rem + 2px);
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0;
}

.form-control-lg::file-selector-button {
  padding: 0.8rem 1.5rem;
  margin: -0.8rem -1.5rem;
  margin-inline-end: 1.5rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.8rem 1.5rem;
  margin: -0.8rem -1.5rem;
  margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.6em + 1rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.4em + 0.8rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.7em + 1.6rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.5rem;
}

.form-control-color:not(:disabled):not(:read-only) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0;
}

.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #29292e;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none;
}

.form-select:focus {
  border-color: #ea110c;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #29292e;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #ea110c;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.form-check-input:checked {
  background-color: #ea110c;
  border-color: #ea110c;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #ea110c;
  border-color: #ea110c;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ea110c'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ea110c;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #f9b8b6;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ea110c;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #f9b8b6;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #a1a1a8;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #a1a1a8;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.7875rem;
  border-radius: 0;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.6;
  color: #a1a1a8;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #a1a1a8;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.btn-primary:hover {
  color: #fff;
  background-color: #c70e0a;
  border-color: #bb0e0a;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #c70e0a;
  border-color: #bb0e0a;
  box-shadow: 0 0 0 0 rgba(237, 53, 48, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #bb0e0a;
  border-color: #b00d09;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(237, 53, 48, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.btn-secondary {
  color: #000;
  background-color: #d0d4d9;
  border-color: #d0d4d9;
}

.btn-secondary:hover {
  color: #000;
  background-color: #d7dadf;
  border-color: #d5d8dd;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #d7dadf;
  border-color: #d5d8dd;
  box-shadow: 0 0 0 0 rgba(177, 180, 184, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #d9dde1;
  border-color: #d5d8dd;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(177, 180, 184, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #d0d4d9;
  border-color: #d0d4d9;
}

.btn-success {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #000;
  background-color: #48b461;
  border-color: #3eb058;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #48b461;
  border-color: #3eb058;
  box-shadow: 0 0 0 0 rgba(34, 142, 59, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #53b96a;
  border-color: #3eb058;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(34, 142, 59, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #000;
  background-color: #3ab0c3;
  border-color: #2eabbf;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #3ab0c3;
  border-color: #2eabbf;
  box-shadow: 0 0 0 0 rgba(20, 138, 156, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #45b5c6;
  border-color: #2eabbf;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(20, 138, 156, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(217, 164, 6, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: 0 0 0 0 rgba(210, 211, 212, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(210, 211, 212, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-dark {
  color: #fff;
  background-color: #19191c;
  border-color: #19191c;
}

.btn-dark:hover {
  color: #fff;
  background-color: #151518;
  border-color: #141416;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #151518;
  border-color: #141416;
  box-shadow: 0 0 0 0 rgba(60, 60, 62, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #141416;
  border-color: #131315;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(60, 60, 62, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #19191c;
  border-color: #19191c;
}

.btn-outline-primary {
  color: #ea110c;
  border-color: #ea110c;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #ea110c;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #d0d4d9;
  border-color: #d0d4d9;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #d0d4d9;
  border-color: #d0d4d9;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(208, 212, 217, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #d0d4d9;
  border-color: #d0d4d9;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(208, 212, 217, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #d0d4d9;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(247, 248, 249, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f7f8f9;
  border-color: #f7f8f9;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(247, 248, 249, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f7f8f9;
  background-color: transparent;
}

.btn-outline-dark {
  color: #19191c;
  border-color: #19191c;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #19191c;
  border-color: #19191c;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(25, 25, 28, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #19191c;
  border-color: #19191c;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(25, 25, 28, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #19191c;
  background-color: transparent;
}

.btn-link {
  font-weight: 500;
  color: #ea110c;
  text-decoration: none;
}

.btn-link:hover {
  color: #bb0e0a;
}

.btn-link:disabled, .btn-link.disabled {
  color: #d0d4d9;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.7875rem;
  border-radius: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 15rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #a1a1a8;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f0f1f3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 2rem;
  clear: both;
  font-weight: 500;
  color: #515155;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #ea110c;
  background-color: transparent;
}

.dropdown-item.active, .dropdown-item:active {
  color: #ea110c;
  text-decoration: none;
  background-color: transparent;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #515155;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 2rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #19191c;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.4rem 2rem;
  color: #515155;
}

.dropdown-menu-dark {
  color: #dfe0e5;
  background-color: #19191c;
  border-color: rgba(0, 0, 0, 0.1);
}

.dropdown-menu-dark .dropdown-item {
  color: #dfe0e5;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: transparent;
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #ea110c;
  background-color: transparent;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #a1a1a8;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.1);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dfe0e5;
}

.dropdown-menu-dark .dropdown-header {
  color: #a1a1a8;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
  color: #29292e;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #bb0e0a;
}

.nav-link.disabled {
  color: #a1a1a8 !important;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dfe0e5;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #f0f1f3 #f0f1f3 #dfe0e5;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #a1a1a8 !important;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #29292e;
  background-color: #fff;
  border-color: #dfe0e5 #dfe0e5 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ea110c;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: #515155;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #ea110c;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #ea110c;
}

.navbar-light .navbar-toggler {
  color: #515155;
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #515155;
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #ea110c;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ea110c !important;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ea110c !important;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #fff;
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #ea110c !important;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 0.75rem 1.25rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid rgba(0, 0, 0, 0.1);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: #fff;
  border-top: 0 solid rgba(0, 0, 0, 0.1);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-group > .card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #d30f0b;
  background-color: #fde7e7;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23d30f0b'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.8rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.8rem;
  color: #a1a1a8;
  content: var(--bs-breadcrumb-divider, "") /* rtl: var(--bs-breadcrumb-divider, "") */;
}

.breadcrumb-item.active {
  color: #a1a1a8;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #515155;
  background-color: #fff;
  border: 1px solid #dfe0e5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.page-link:focus {
  z-index: 3;
  color: #bb0e0a;
  background-color: #f0f1f3;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.page-item.disabled .page-link {
  color: #d0d4d9;
  pointer-events: none;
  background-color: #fff;
  border-color: #dfe0e5;
}

.page-link {
  padding: 0.7rem 1rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #8c0a07;
  background-color: #fbcfce;
  border-color: #f9b8b6;
}

.alert-primary .alert-link {
  color: #700806;
}

.alert-secondary {
  color: #535557;
  background-color: #f6f6f7;
  border-color: #f1f2f4;
}

.alert-secondary .alert-link {
  color: #424446;
}

.alert-success {
  color: #186429;
  background-color: #d4edda;
  border-color: #bfe5c7;
}

.alert-success .alert-link {
  color: #135021;
}

.alert-info {
  color: #0e616e;
  background-color: #d1ecf1;
  border-color: #b9e3ea;
}

.alert-info .alert-link {
  color: #0b4e58;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636364;
  background-color: #fdfefe;
  border-color: #fdfdfd;
}

.alert-light .alert-link {
  color: #4f4f50;
}

.alert-dark {
  color: #0f0f11;
  background-color: #d1d1d2;
  border-color: #bababb;
}

.alert-dark .alert-link {
  color: #0c0c0e;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ea110c;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #515155;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ea110c;
  border-color: #ea110c;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #8c0a07;
  background-color: #fbcfce;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #8c0a07;
  background-color: #e2bab9;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #8c0a07;
  border-color: #8c0a07;
}

.list-group-item-secondary {
  color: #535557;
  background-color: #f6f6f7;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #535557;
  background-color: #ddddde;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #535557;
  border-color: #535557;
}

.list-group-item-success {
  color: #186429;
  background-color: #d4edda;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #186429;
  background-color: #bfd5c4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #186429;
  border-color: #186429;
}

.list-group-item-info {
  color: #0e616e;
  background-color: #d1ecf1;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0e616e;
  background-color: #bcd4d9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0e616e;
  border-color: #0e616e;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636364;
  background-color: #fdfefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636364;
  background-color: #e4e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636364;
  border-color: #636364;
}

.list-group-item-dark {
  color: #0f0f11;
  background-color: #d1d1d2;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0f0f11;
  background-color: #bcbcbd;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0f0f11;
  border-color: #0f0f11;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #19191c;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 500px;
  border-right: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 500px;
  border-left: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  max-height: 100%;
  border-bottom: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 100vh;
  max-height: 100%;
  border-top: 0 solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #ea110c;
}

.link-primary:hover, .link-primary:focus {
  color: #bb0e0a;
}

.link-secondary {
  color: #d0d4d9;
}

.link-secondary:hover, .link-secondary:focus {
  color: #d9dde1;
}

.link-success {
  color: #28a745;
}

.link-success:hover, .link-success:focus {
  color: #53b96a;
}

.link-info {
  color: #17a2b8;
}

.link-info:hover, .link-info:focus {
  color: #45b5c6;
}

.link-warning {
  color: #ffc107;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f7f8f9;
}

.link-light:hover, .link-light:focus {
  color: #f9f9fa;
}

.link-dark {
  color: #19191c;
}

.link-dark:hover, .link-dark:focus {
  color: #141416;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0px 0px 20px rgba(83, 88, 93, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(83, 88, 93, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(83, 88, 93, 0.15) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ea110c !important;
}

.border-secondary {
  border-color: #d0d4d9 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f7f8f9 !important;
}

.border-dark {
  border-color: #19191c !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 4.5rem !important;
}

.gap-7 {
  gap: 6rem !important;
}

.gap-8 {
  gap: 8rem !important;
}

.gap-9 {
  gap: 11rem !important;
}

.gap-11 {
  gap: 20rem !important;
}

.gap-10 {
  gap: 14rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.m-8 {
  margin: 8rem !important;
}

.m-9 {
  margin: 11rem !important;
}

.m-11 {
  margin: 20rem !important;
}

.m-10 {
  margin: 14rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-7 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}

.mx-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important;
}

.mx-9 {
  margin-right: 11rem !important;
  margin-left: 11rem !important;
}

.mx-11 {
  margin-right: 20rem !important;
  margin-left: 20rem !important;
}

.mx-10 {
  margin-right: 14rem !important;
  margin-left: 14rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-7 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.my-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.my-9 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.my-11 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.my-10 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 4.5rem !important;
}

.mt-7 {
  margin-top: 6rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 11rem !important;
}

.mt-11 {
  margin-top: 20rem !important;
}

.mt-10 {
  margin-top: 14rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 4.5rem !important;
}

.me-7 {
  margin-right: 6rem !important;
}

.me-8 {
  margin-right: 8rem !important;
}

.me-9 {
  margin-right: 11rem !important;
}

.me-11 {
  margin-right: 20rem !important;
}

.me-10 {
  margin-right: 14rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4.5rem !important;
}

.mb-7 {
  margin-bottom: 6rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 11rem !important;
}

.mb-11 {
  margin-bottom: 20rem !important;
}

.mb-10 {
  margin-bottom: 14rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 4.5rem !important;
}

.ms-7 {
  margin-left: 6rem !important;
}

.ms-8 {
  margin-left: 8rem !important;
}

.ms-9 {
  margin-left: 11rem !important;
}

.ms-11 {
  margin-left: 20rem !important;
}

.ms-10 {
  margin-left: 14rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.m-n8 {
  margin: -8rem !important;
}

.m-n9 {
  margin: -11rem !important;
}

.m-n11 {
  margin: -20rem !important;
}

.m-n10 {
  margin: -14rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.mx-n7 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}

.mx-n8 {
  margin-right: -8rem !important;
  margin-left: -8rem !important;
}

.mx-n9 {
  margin-right: -11rem !important;
  margin-left: -11rem !important;
}

.mx-n11 {
  margin-right: -20rem !important;
  margin-left: -20rem !important;
}

.mx-n10 {
  margin-right: -14rem !important;
  margin-left: -14rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.my-n7 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.my-n8 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.my-n9 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.my-n11 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.my-n10 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.mt-n6 {
  margin-top: -4.5rem !important;
}

.mt-n7 {
  margin-top: -6rem !important;
}

.mt-n8 {
  margin-top: -8rem !important;
}

.mt-n9 {
  margin-top: -11rem !important;
}

.mt-n11 {
  margin-top: -20rem !important;
}

.mt-n10 {
  margin-top: -14rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.me-n6 {
  margin-right: -4.5rem !important;
}

.me-n7 {
  margin-right: -6rem !important;
}

.me-n8 {
  margin-right: -8rem !important;
}

.me-n9 {
  margin-right: -11rem !important;
}

.me-n11 {
  margin-right: -20rem !important;
}

.me-n10 {
  margin-right: -14rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.mb-n6 {
  margin-bottom: -4.5rem !important;
}

.mb-n7 {
  margin-bottom: -6rem !important;
}

.mb-n8 {
  margin-bottom: -8rem !important;
}

.mb-n9 {
  margin-bottom: -11rem !important;
}

.mb-n11 {
  margin-bottom: -20rem !important;
}

.mb-n10 {
  margin-bottom: -14rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.ms-n6 {
  margin-left: -4.5rem !important;
}

.ms-n7 {
  margin-left: -6rem !important;
}

.ms-n8 {
  margin-left: -8rem !important;
}

.ms-n9 {
  margin-left: -11rem !important;
}

.ms-n11 {
  margin-left: -20rem !important;
}

.ms-n10 {
  margin-left: -14rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.p-8 {
  padding: 8rem !important;
}

.p-9 {
  padding: 11rem !important;
}

.p-11 {
  padding: 20rem !important;
}

.p-10 {
  padding: 14rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.px-7 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

.px-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.px-9 {
  padding-right: 11rem !important;
  padding-left: 11rem !important;
}

.px-11 {
  padding-right: 20rem !important;
  padding-left: 20rem !important;
}

.px-10 {
  padding-right: 14rem !important;
  padding-left: 14rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.py-7 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.py-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.py-9 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.py-11 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.py-10 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.pt-7 {
  padding-top: 6rem !important;
}

.pt-8 {
  padding-top: 8rem !important;
}

.pt-9 {
  padding-top: 11rem !important;
}

.pt-11 {
  padding-top: 20rem !important;
}

.pt-10 {
  padding-top: 14rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 4.5rem !important;
}

.pe-7 {
  padding-right: 6rem !important;
}

.pe-8 {
  padding-right: 8rem !important;
}

.pe-9 {
  padding-right: 11rem !important;
}

.pe-11 {
  padding-right: 20rem !important;
}

.pe-10 {
  padding-right: 14rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 4.5rem !important;
}

.pb-7 {
  padding-bottom: 6rem !important;
}

.pb-8 {
  padding-bottom: 8rem !important;
}

.pb-9 {
  padding-bottom: 11rem !important;
}

.pb-11 {
  padding-bottom: 20rem !important;
}

.pb-10 {
  padding-bottom: 14rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 4.5rem !important;
}

.ps-7 {
  padding-left: 6rem !important;
}

.ps-8 {
  padding-left: 8rem !important;
}

.ps-9 {
  padding-left: 11rem !important;
}

.ps-11 {
  padding-left: 20rem !important;
}

.ps-10 {
  padding-left: 14rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(2.15rem + 1.8vw) !important;
}

.fs-2 {
  font-size: calc(2.0625rem + 0.75vw) !important;
}

.fs-3 {
  font-size: 1.75rem !important;
}

.fs-4 {
  font-size: 1.3125rem !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 400 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.4 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 1.7 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #ea110c !important;
}

.text-secondary {
  color: #d0d4d9 !important;
}

.text-success {
  color: #28a745 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f7f8f9 !important;
}

.text-dark {
  color: #19191c !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #a1a1a8 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #ea110c !important;
}

.bg-secondary {
  background-color: #d0d4d9 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f7f8f9 !important;
}

.bg-dark {
  background-color: #19191c !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0 !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0 !important;
}

.rounded-2 {
  border-radius: 0 !important;
}

.rounded-3 {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-end {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-start {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .gap-sm-6 {
    gap: 4.5rem !important;
  }
  .gap-sm-7 {
    gap: 6rem !important;
  }
  .gap-sm-8 {
    gap: 8rem !important;
  }
  .gap-sm-9 {
    gap: 11rem !important;
  }
  .gap-sm-11 {
    gap: 20rem !important;
  }
  .gap-sm-10 {
    gap: 14rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-6 {
    margin: 4.5rem !important;
  }
  .m-sm-7 {
    margin: 6rem !important;
  }
  .m-sm-8 {
    margin: 8rem !important;
  }
  .m-sm-9 {
    margin: 11rem !important;
  }
  .m-sm-11 {
    margin: 20rem !important;
  }
  .m-sm-10 {
    margin: 14rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-sm-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-sm-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-sm-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-sm-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-sm-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-sm-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-sm-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-sm-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-sm-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-7 {
    margin-top: 6rem !important;
  }
  .mt-sm-8 {
    margin-top: 8rem !important;
  }
  .mt-sm-9 {
    margin-top: 11rem !important;
  }
  .mt-sm-11 {
    margin-top: 20rem !important;
  }
  .mt-sm-10 {
    margin-top: 14rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-6 {
    margin-right: 4.5rem !important;
  }
  .me-sm-7 {
    margin-right: 6rem !important;
  }
  .me-sm-8 {
    margin-right: 8rem !important;
  }
  .me-sm-9 {
    margin-right: 11rem !important;
  }
  .me-sm-11 {
    margin-right: 20rem !important;
  }
  .me-sm-10 {
    margin-right: 14rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 6rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 8rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 11rem !important;
  }
  .mb-sm-11 {
    margin-bottom: 20rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 14rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-6 {
    margin-left: 4.5rem !important;
  }
  .ms-sm-7 {
    margin-left: 6rem !important;
  }
  .ms-sm-8 {
    margin-left: 8rem !important;
  }
  .ms-sm-9 {
    margin-left: 11rem !important;
  }
  .ms-sm-11 {
    margin-left: 20rem !important;
  }
  .ms-sm-10 {
    margin-left: 14rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .m-sm-n6 {
    margin: -4.5rem !important;
  }
  .m-sm-n7 {
    margin: -6rem !important;
  }
  .m-sm-n8 {
    margin: -8rem !important;
  }
  .m-sm-n9 {
    margin: -11rem !important;
  }
  .m-sm-n11 {
    margin: -20rem !important;
  }
  .m-sm-n10 {
    margin: -14rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-sm-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-sm-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-sm-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-sm-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-sm-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-sm-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-sm-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-sm-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-sm-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-sm-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-sm-n7 {
    margin-top: -6rem !important;
  }
  .mt-sm-n8 {
    margin-top: -8rem !important;
  }
  .mt-sm-n9 {
    margin-top: -11rem !important;
  }
  .mt-sm-n11 {
    margin-top: -20rem !important;
  }
  .mt-sm-n10 {
    margin-top: -14rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .me-sm-n6 {
    margin-right: -4.5rem !important;
  }
  .me-sm-n7 {
    margin-right: -6rem !important;
  }
  .me-sm-n8 {
    margin-right: -8rem !important;
  }
  .me-sm-n9 {
    margin-right: -11rem !important;
  }
  .me-sm-n11 {
    margin-right: -20rem !important;
  }
  .me-sm-n10 {
    margin-right: -14rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-sm-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .ms-sm-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-sm-n7 {
    margin-left: -6rem !important;
  }
  .ms-sm-n8 {
    margin-left: -8rem !important;
  }
  .ms-sm-n9 {
    margin-left: -11rem !important;
  }
  .ms-sm-n11 {
    margin-left: -20rem !important;
  }
  .ms-sm-n10 {
    margin-left: -14rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .p-sm-6 {
    padding: 4.5rem !important;
  }
  .p-sm-7 {
    padding: 6rem !important;
  }
  .p-sm-8 {
    padding: 8rem !important;
  }
  .p-sm-9 {
    padding: 11rem !important;
  }
  .p-sm-11 {
    padding: 20rem !important;
  }
  .p-sm-10 {
    padding: 14rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-sm-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-sm-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-sm-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-sm-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-sm-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-sm-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-sm-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-sm-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-sm-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-7 {
    padding-top: 6rem !important;
  }
  .pt-sm-8 {
    padding-top: 8rem !important;
  }
  .pt-sm-9 {
    padding-top: 11rem !important;
  }
  .pt-sm-11 {
    padding-top: 20rem !important;
  }
  .pt-sm-10 {
    padding-top: 14rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pe-sm-6 {
    padding-right: 4.5rem !important;
  }
  .pe-sm-7 {
    padding-right: 6rem !important;
  }
  .pe-sm-8 {
    padding-right: 8rem !important;
  }
  .pe-sm-9 {
    padding-right: 11rem !important;
  }
  .pe-sm-11 {
    padding-right: 20rem !important;
  }
  .pe-sm-10 {
    padding-right: 14rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 6rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 8rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 11rem !important;
  }
  .pb-sm-11 {
    padding-bottom: 20rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 14rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .ps-sm-6 {
    padding-left: 4.5rem !important;
  }
  .ps-sm-7 {
    padding-left: 6rem !important;
  }
  .ps-sm-8 {
    padding-left: 8rem !important;
  }
  .ps-sm-9 {
    padding-left: 11rem !important;
  }
  .ps-sm-11 {
    padding-left: 20rem !important;
  }
  .ps-sm-10 {
    padding-left: 14rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .gap-md-6 {
    gap: 4.5rem !important;
  }
  .gap-md-7 {
    gap: 6rem !important;
  }
  .gap-md-8 {
    gap: 8rem !important;
  }
  .gap-md-9 {
    gap: 11rem !important;
  }
  .gap-md-11 {
    gap: 20rem !important;
  }
  .gap-md-10 {
    gap: 14rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-6 {
    margin: 4.5rem !important;
  }
  .m-md-7 {
    margin: 6rem !important;
  }
  .m-md-8 {
    margin: 8rem !important;
  }
  .m-md-9 {
    margin: 11rem !important;
  }
  .m-md-11 {
    margin: 20rem !important;
  }
  .m-md-10 {
    margin: 14rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-md-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-md-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-md-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-md-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-md-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-md-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-md-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-md-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-md-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-6 {
    margin-top: 4.5rem !important;
  }
  .mt-md-7 {
    margin-top: 6rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 11rem !important;
  }
  .mt-md-11 {
    margin-top: 20rem !important;
  }
  .mt-md-10 {
    margin-top: 14rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-6 {
    margin-right: 4.5rem !important;
  }
  .me-md-7 {
    margin-right: 6rem !important;
  }
  .me-md-8 {
    margin-right: 8rem !important;
  }
  .me-md-9 {
    margin-right: 11rem !important;
  }
  .me-md-11 {
    margin-right: 20rem !important;
  }
  .me-md-10 {
    margin-right: 14rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-7 {
    margin-bottom: 6rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 11rem !important;
  }
  .mb-md-11 {
    margin-bottom: 20rem !important;
  }
  .mb-md-10 {
    margin-bottom: 14rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-6 {
    margin-left: 4.5rem !important;
  }
  .ms-md-7 {
    margin-left: 6rem !important;
  }
  .ms-md-8 {
    margin-left: 8rem !important;
  }
  .ms-md-9 {
    margin-left: 11rem !important;
  }
  .ms-md-11 {
    margin-left: 20rem !important;
  }
  .ms-md-10 {
    margin-left: 14rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .m-md-n6 {
    margin: -4.5rem !important;
  }
  .m-md-n7 {
    margin: -6rem !important;
  }
  .m-md-n8 {
    margin: -8rem !important;
  }
  .m-md-n9 {
    margin: -11rem !important;
  }
  .m-md-n11 {
    margin: -20rem !important;
  }
  .m-md-n10 {
    margin: -14rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-md-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-md-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-md-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-md-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-md-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-md-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-md-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-md-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-md-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-md-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-md-n7 {
    margin-top: -6rem !important;
  }
  .mt-md-n8 {
    margin-top: -8rem !important;
  }
  .mt-md-n9 {
    margin-top: -11rem !important;
  }
  .mt-md-n11 {
    margin-top: -20rem !important;
  }
  .mt-md-n10 {
    margin-top: -14rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .me-md-n6 {
    margin-right: -4.5rem !important;
  }
  .me-md-n7 {
    margin-right: -6rem !important;
  }
  .me-md-n8 {
    margin-right: -8rem !important;
  }
  .me-md-n9 {
    margin-right: -11rem !important;
  }
  .me-md-n11 {
    margin-right: -20rem !important;
  }
  .me-md-n10 {
    margin-right: -14rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-md-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .ms-md-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-md-n7 {
    margin-left: -6rem !important;
  }
  .ms-md-n8 {
    margin-left: -8rem !important;
  }
  .ms-md-n9 {
    margin-left: -11rem !important;
  }
  .ms-md-n11 {
    margin-left: -20rem !important;
  }
  .ms-md-n10 {
    margin-left: -14rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .p-md-6 {
    padding: 4.5rem !important;
  }
  .p-md-7 {
    padding: 6rem !important;
  }
  .p-md-8 {
    padding: 8rem !important;
  }
  .p-md-9 {
    padding: 11rem !important;
  }
  .p-md-11 {
    padding: 20rem !important;
  }
  .p-md-10 {
    padding: 14rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-md-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-md-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-md-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-md-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-md-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-md-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-md-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-md-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-md-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pt-md-6 {
    padding-top: 4.5rem !important;
  }
  .pt-md-7 {
    padding-top: 6rem !important;
  }
  .pt-md-8 {
    padding-top: 8rem !important;
  }
  .pt-md-9 {
    padding-top: 11rem !important;
  }
  .pt-md-11 {
    padding-top: 20rem !important;
  }
  .pt-md-10 {
    padding-top: 14rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pe-md-6 {
    padding-right: 4.5rem !important;
  }
  .pe-md-7 {
    padding-right: 6rem !important;
  }
  .pe-md-8 {
    padding-right: 8rem !important;
  }
  .pe-md-9 {
    padding-right: 11rem !important;
  }
  .pe-md-11 {
    padding-right: 20rem !important;
  }
  .pe-md-10 {
    padding-right: 14rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-7 {
    padding-bottom: 6rem !important;
  }
  .pb-md-8 {
    padding-bottom: 8rem !important;
  }
  .pb-md-9 {
    padding-bottom: 11rem !important;
  }
  .pb-md-11 {
    padding-bottom: 20rem !important;
  }
  .pb-md-10 {
    padding-bottom: 14rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .ps-md-6 {
    padding-left: 4.5rem !important;
  }
  .ps-md-7 {
    padding-left: 6rem !important;
  }
  .ps-md-8 {
    padding-left: 8rem !important;
  }
  .ps-md-9 {
    padding-left: 11rem !important;
  }
  .ps-md-11 {
    padding-left: 20rem !important;
  }
  .ps-md-10 {
    padding-left: 14rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .gap-lg-6 {
    gap: 4.5rem !important;
  }
  .gap-lg-7 {
    gap: 6rem !important;
  }
  .gap-lg-8 {
    gap: 8rem !important;
  }
  .gap-lg-9 {
    gap: 11rem !important;
  }
  .gap-lg-11 {
    gap: 20rem !important;
  }
  .gap-lg-10 {
    gap: 14rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-6 {
    margin: 4.5rem !important;
  }
  .m-lg-7 {
    margin: 6rem !important;
  }
  .m-lg-8 {
    margin: 8rem !important;
  }
  .m-lg-9 {
    margin: 11rem !important;
  }
  .m-lg-11 {
    margin: 20rem !important;
  }
  .m-lg-10 {
    margin: 14rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-lg-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-lg-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-lg-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-lg-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-lg-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-lg-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-lg-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-lg-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-lg-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-7 {
    margin-top: 6rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 11rem !important;
  }
  .mt-lg-11 {
    margin-top: 20rem !important;
  }
  .mt-lg-10 {
    margin-top: 14rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-6 {
    margin-right: 4.5rem !important;
  }
  .me-lg-7 {
    margin-right: 6rem !important;
  }
  .me-lg-8 {
    margin-right: 8rem !important;
  }
  .me-lg-9 {
    margin-right: 11rem !important;
  }
  .me-lg-11 {
    margin-right: 20rem !important;
  }
  .me-lg-10 {
    margin-right: 14rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 11rem !important;
  }
  .mb-lg-11 {
    margin-bottom: 20rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 14rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-6 {
    margin-left: 4.5rem !important;
  }
  .ms-lg-7 {
    margin-left: 6rem !important;
  }
  .ms-lg-8 {
    margin-left: 8rem !important;
  }
  .ms-lg-9 {
    margin-left: 11rem !important;
  }
  .ms-lg-11 {
    margin-left: 20rem !important;
  }
  .ms-lg-10 {
    margin-left: 14rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .m-lg-n6 {
    margin: -4.5rem !important;
  }
  .m-lg-n7 {
    margin: -6rem !important;
  }
  .m-lg-n8 {
    margin: -8rem !important;
  }
  .m-lg-n9 {
    margin: -11rem !important;
  }
  .m-lg-n11 {
    margin: -20rem !important;
  }
  .m-lg-n10 {
    margin: -14rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-lg-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-lg-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-lg-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-lg-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-lg-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-lg-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-lg-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-lg-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-lg-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-lg-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-lg-n7 {
    margin-top: -6rem !important;
  }
  .mt-lg-n8 {
    margin-top: -8rem !important;
  }
  .mt-lg-n9 {
    margin-top: -11rem !important;
  }
  .mt-lg-n11 {
    margin-top: -20rem !important;
  }
  .mt-lg-n10 {
    margin-top: -14rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .me-lg-n6 {
    margin-right: -4.5rem !important;
  }
  .me-lg-n7 {
    margin-right: -6rem !important;
  }
  .me-lg-n8 {
    margin-right: -8rem !important;
  }
  .me-lg-n9 {
    margin-right: -11rem !important;
  }
  .me-lg-n11 {
    margin-right: -20rem !important;
  }
  .me-lg-n10 {
    margin-right: -14rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-lg-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .ms-lg-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-lg-n7 {
    margin-left: -6rem !important;
  }
  .ms-lg-n8 {
    margin-left: -8rem !important;
  }
  .ms-lg-n9 {
    margin-left: -11rem !important;
  }
  .ms-lg-n11 {
    margin-left: -20rem !important;
  }
  .ms-lg-n10 {
    margin-left: -14rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .p-lg-6 {
    padding: 4.5rem !important;
  }
  .p-lg-7 {
    padding: 6rem !important;
  }
  .p-lg-8 {
    padding: 8rem !important;
  }
  .p-lg-9 {
    padding: 11rem !important;
  }
  .p-lg-11 {
    padding: 20rem !important;
  }
  .p-lg-10 {
    padding: 14rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-lg-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-lg-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-lg-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-lg-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-lg-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-lg-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-lg-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-lg-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-lg-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-7 {
    padding-top: 6rem !important;
  }
  .pt-lg-8 {
    padding-top: 8rem !important;
  }
  .pt-lg-9 {
    padding-top: 11rem !important;
  }
  .pt-lg-11 {
    padding-top: 20rem !important;
  }
  .pt-lg-10 {
    padding-top: 14rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pe-lg-6 {
    padding-right: 4.5rem !important;
  }
  .pe-lg-7 {
    padding-right: 6rem !important;
  }
  .pe-lg-8 {
    padding-right: 8rem !important;
  }
  .pe-lg-9 {
    padding-right: 11rem !important;
  }
  .pe-lg-11 {
    padding-right: 20rem !important;
  }
  .pe-lg-10 {
    padding-right: 14rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 6rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 8rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 11rem !important;
  }
  .pb-lg-11 {
    padding-bottom: 20rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 14rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .ps-lg-6 {
    padding-left: 4.5rem !important;
  }
  .ps-lg-7 {
    padding-left: 6rem !important;
  }
  .ps-lg-8 {
    padding-left: 8rem !important;
  }
  .ps-lg-9 {
    padding-left: 11rem !important;
  }
  .ps-lg-11 {
    padding-left: 20rem !important;
  }
  .ps-lg-10 {
    padding-left: 14rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .gap-xl-6 {
    gap: 4.5rem !important;
  }
  .gap-xl-7 {
    gap: 6rem !important;
  }
  .gap-xl-8 {
    gap: 8rem !important;
  }
  .gap-xl-9 {
    gap: 11rem !important;
  }
  .gap-xl-11 {
    gap: 20rem !important;
  }
  .gap-xl-10 {
    gap: 14rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-6 {
    margin: 4.5rem !important;
  }
  .m-xl-7 {
    margin: 6rem !important;
  }
  .m-xl-8 {
    margin: 8rem !important;
  }
  .m-xl-9 {
    margin: 11rem !important;
  }
  .m-xl-11 {
    margin: 20rem !important;
  }
  .m-xl-10 {
    margin: 14rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xl-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xl-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-7 {
    margin-top: 6rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 11rem !important;
  }
  .mt-xl-11 {
    margin-top: 20rem !important;
  }
  .mt-xl-10 {
    margin-top: 14rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xl-7 {
    margin-right: 6rem !important;
  }
  .me-xl-8 {
    margin-right: 8rem !important;
  }
  .me-xl-9 {
    margin-right: 11rem !important;
  }
  .me-xl-11 {
    margin-right: 20rem !important;
  }
  .me-xl-10 {
    margin-right: 14rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 11rem !important;
  }
  .mb-xl-11 {
    margin-bottom: 20rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xl-7 {
    margin-left: 6rem !important;
  }
  .ms-xl-8 {
    margin-left: 8rem !important;
  }
  .ms-xl-9 {
    margin-left: 11rem !important;
  }
  .ms-xl-11 {
    margin-left: 20rem !important;
  }
  .ms-xl-10 {
    margin-left: 14rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .m-xl-n6 {
    margin: -4.5rem !important;
  }
  .m-xl-n7 {
    margin: -6rem !important;
  }
  .m-xl-n8 {
    margin: -8rem !important;
  }
  .m-xl-n9 {
    margin: -11rem !important;
  }
  .m-xl-n11 {
    margin: -20rem !important;
  }
  .m-xl-n10 {
    margin: -14rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-xl-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-xl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-xl-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-xl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xl-n8 {
    margin-top: -8rem !important;
  }
  .mt-xl-n9 {
    margin-top: -11rem !important;
  }
  .mt-xl-n11 {
    margin-top: -20rem !important;
  }
  .mt-xl-n10 {
    margin-top: -14rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .me-xl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xl-n7 {
    margin-right: -6rem !important;
  }
  .me-xl-n8 {
    margin-right: -8rem !important;
  }
  .me-xl-n9 {
    margin-right: -11rem !important;
  }
  .me-xl-n11 {
    margin-right: -20rem !important;
  }
  .me-xl-n10 {
    margin-right: -14rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-xl-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xl-n8 {
    margin-left: -8rem !important;
  }
  .ms-xl-n9 {
    margin-left: -11rem !important;
  }
  .ms-xl-n11 {
    margin-left: -20rem !important;
  }
  .ms-xl-n10 {
    margin-left: -14rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .p-xl-6 {
    padding: 4.5rem !important;
  }
  .p-xl-7 {
    padding: 6rem !important;
  }
  .p-xl-8 {
    padding: 8rem !important;
  }
  .p-xl-9 {
    padding: 11rem !important;
  }
  .p-xl-11 {
    padding: 20rem !important;
  }
  .p-xl-10 {
    padding: 14rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xl-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xl-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-7 {
    padding-top: 6rem !important;
  }
  .pt-xl-8 {
    padding-top: 8rem !important;
  }
  .pt-xl-9 {
    padding-top: 11rem !important;
  }
  .pt-xl-11 {
    padding-top: 20rem !important;
  }
  .pt-xl-10 {
    padding-top: 14rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pe-xl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xl-7 {
    padding-right: 6rem !important;
  }
  .pe-xl-8 {
    padding-right: 8rem !important;
  }
  .pe-xl-9 {
    padding-right: 11rem !important;
  }
  .pe-xl-11 {
    padding-right: 20rem !important;
  }
  .pe-xl-10 {
    padding-right: 14rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 11rem !important;
  }
  .pb-xl-11 {
    padding-bottom: 20rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 14rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .ps-xl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xl-7 {
    padding-left: 6rem !important;
  }
  .ps-xl-8 {
    padding-left: 8rem !important;
  }
  .ps-xl-9 {
    padding-left: 11rem !important;
  }
  .ps-xl-11 {
    padding-left: 20rem !important;
  }
  .ps-xl-10 {
    padding-left: 14rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .gap-xxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxl-7 {
    gap: 6rem !important;
  }
  .gap-xxl-8 {
    gap: 8rem !important;
  }
  .gap-xxl-9 {
    gap: 11rem !important;
  }
  .gap-xxl-11 {
    gap: 20rem !important;
  }
  .gap-xxl-10 {
    gap: 14rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxl-7 {
    margin: 6rem !important;
  }
  .m-xxl-8 {
    margin: 8rem !important;
  }
  .m-xxl-9 {
    margin: 11rem !important;
  }
  .m-xxl-11 {
    margin: 20rem !important;
  }
  .m-xxl-10 {
    margin: 14rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xxl-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xxl-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 11rem !important;
  }
  .mt-xxl-11 {
    margin-top: 20rem !important;
  }
  .mt-xxl-10 {
    margin-top: 14rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxl-9 {
    margin-right: 11rem !important;
  }
  .me-xxl-11 {
    margin-right: 20rem !important;
  }
  .me-xxl-10 {
    margin-right: 14rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 11rem !important;
  }
  .mb-xxl-11 {
    margin-bottom: 20rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxl-9 {
    margin-left: 11rem !important;
  }
  .ms-xxl-11 {
    margin-left: 20rem !important;
  }
  .ms-xxl-10 {
    margin-left: 14rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .m-xxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxl-n7 {
    margin: -6rem !important;
  }
  .m-xxl-n8 {
    margin: -8rem !important;
  }
  .m-xxl-n9 {
    margin: -11rem !important;
  }
  .m-xxl-n11 {
    margin: -20rem !important;
  }
  .m-xxl-n10 {
    margin: -14rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-xxl-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xxl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-xxl-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-xxl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -8rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -11rem !important;
  }
  .mt-xxl-n11 {
    margin-top: -20rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -14rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxl-n8 {
    margin-right: -8rem !important;
  }
  .me-xxl-n9 {
    margin-right: -11rem !important;
  }
  .me-xxl-n11 {
    margin-right: -20rem !important;
  }
  .me-xxl-n10 {
    margin-right: -14rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-xxl-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -8rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -11rem !important;
  }
  .ms-xxl-n11 {
    margin-left: -20rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -14rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .p-xxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxl-7 {
    padding: 6rem !important;
  }
  .p-xxl-8 {
    padding: 8rem !important;
  }
  .p-xxl-9 {
    padding: 11rem !important;
  }
  .p-xxl-11 {
    padding: 20rem !important;
  }
  .p-xxl-10 {
    padding: 14rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xxl-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xxl-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxl-9 {
    padding-top: 11rem !important;
  }
  .pt-xxl-11 {
    padding-top: 20rem !important;
  }
  .pt-xxl-10 {
    padding-top: 14rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxl-8 {
    padding-right: 8rem !important;
  }
  .pe-xxl-9 {
    padding-right: 11rem !important;
  }
  .pe-xxl-11 {
    padding-right: 20rem !important;
  }
  .pe-xxl-10 {
    padding-right: 14rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 11rem !important;
  }
  .pb-xxl-11 {
    padding-bottom: 20rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 14rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxl-8 {
    padding-left: 8rem !important;
  }
  .ps-xxl-9 {
    padding-left: 11rem !important;
  }
  .ps-xxl-11 {
    padding-left: 20rem !important;
  }
  .ps-xxl-10 {
    padding-left: 14rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxxl-start {
    float: left !important;
  }
  .float-xxxl-end {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-grid {
    display: grid !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxxl-none {
    display: none !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxxl-0 {
    gap: 0 !important;
  }
  .gap-xxxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxxl-3 {
    gap: 1rem !important;
  }
  .gap-xxxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxxl-5 {
    gap: 3rem !important;
  }
  .gap-xxxl-6 {
    gap: 4.5rem !important;
  }
  .gap-xxxl-7 {
    gap: 6rem !important;
  }
  .gap-xxxl-8 {
    gap: 8rem !important;
  }
  .gap-xxxl-9 {
    gap: 11rem !important;
  }
  .gap-xxxl-11 {
    gap: 20rem !important;
  }
  .gap-xxxl-10 {
    gap: 14rem !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
  .order-xxxl-first {
    order: -1 !important;
  }
  .order-xxxl-0 {
    order: 0 !important;
  }
  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }
  .order-xxxl-last {
    order: 6 !important;
  }
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .m-xxxl-6 {
    margin: 4.5rem !important;
  }
  .m-xxxl-7 {
    margin: 6rem !important;
  }
  .m-xxxl-8 {
    margin: 8rem !important;
  }
  .m-xxxl-9 {
    margin: 11rem !important;
  }
  .m-xxxl-11 {
    margin: 20rem !important;
  }
  .m-xxxl-10 {
    margin: 14rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mx-xxxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important;
  }
  .mx-xxxl-7 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .mx-xxxl-8 {
    margin-right: 8rem !important;
    margin-left: 8rem !important;
  }
  .mx-xxxl-9 {
    margin-right: 11rem !important;
    margin-left: 11rem !important;
  }
  .mx-xxxl-11 {
    margin-right: 20rem !important;
    margin-left: 20rem !important;
  }
  .mx-xxxl-10 {
    margin-right: 14rem !important;
    margin-left: 14rem !important;
  }
  .mx-xxxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxxl-7 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .my-xxxl-8 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }
  .my-xxxl-9 {
    margin-top: 11rem !important;
    margin-bottom: 11rem !important;
  }
  .my-xxxl-11 {
    margin-top: 20rem !important;
    margin-bottom: 20rem !important;
  }
  .my-xxxl-10 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }
  .my-xxxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxxl-6 {
    margin-top: 4.5rem !important;
  }
  .mt-xxxl-7 {
    margin-top: 6rem !important;
  }
  .mt-xxxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxxl-9 {
    margin-top: 11rem !important;
  }
  .mt-xxxl-11 {
    margin-top: 20rem !important;
  }
  .mt-xxxl-10 {
    margin-top: 14rem !important;
  }
  .mt-xxxl-auto {
    margin-top: auto !important;
  }
  .me-xxxl-0 {
    margin-right: 0 !important;
  }
  .me-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxxl-6 {
    margin-right: 4.5rem !important;
  }
  .me-xxxl-7 {
    margin-right: 6rem !important;
  }
  .me-xxxl-8 {
    margin-right: 8rem !important;
  }
  .me-xxxl-9 {
    margin-right: 11rem !important;
  }
  .me-xxxl-11 {
    margin-right: 20rem !important;
  }
  .me-xxxl-10 {
    margin-right: 14rem !important;
  }
  .me-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxxl-6 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxxl-7 {
    margin-bottom: 6rem !important;
  }
  .mb-xxxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxxl-9 {
    margin-bottom: 11rem !important;
  }
  .mb-xxxl-11 {
    margin-bottom: 20rem !important;
  }
  .mb-xxxl-10 {
    margin-bottom: 14rem !important;
  }
  .mb-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxxl-6 {
    margin-left: 4.5rem !important;
  }
  .ms-xxxl-7 {
    margin-left: 6rem !important;
  }
  .ms-xxxl-8 {
    margin-left: 8rem !important;
  }
  .ms-xxxl-9 {
    margin-left: 11rem !important;
  }
  .ms-xxxl-11 {
    margin-left: 20rem !important;
  }
  .ms-xxxl-10 {
    margin-left: 14rem !important;
  }
  .ms-xxxl-auto {
    margin-left: auto !important;
  }
  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxxl-n3 {
    margin: -1rem !important;
  }
  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxxl-n5 {
    margin: -3rem !important;
  }
  .m-xxxl-n6 {
    margin: -4.5rem !important;
  }
  .m-xxxl-n7 {
    margin: -6rem !important;
  }
  .m-xxxl-n8 {
    margin: -8rem !important;
  }
  .m-xxxl-n9 {
    margin: -11rem !important;
  }
  .m-xxxl-n11 {
    margin: -20rem !important;
  }
  .m-xxxl-n10 {
    margin: -14rem !important;
  }
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .mx-xxxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important;
  }
  .mx-xxxl-n7 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .mx-xxxl-n8 {
    margin-right: -8rem !important;
    margin-left: -8rem !important;
  }
  .mx-xxxl-n9 {
    margin-right: -11rem !important;
    margin-left: -11rem !important;
  }
  .mx-xxxl-n11 {
    margin-right: -20rem !important;
    margin-left: -20rem !important;
  }
  .mx-xxxl-n10 {
    margin-right: -14rem !important;
    margin-left: -14rem !important;
  }
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .my-xxxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }
  .my-xxxl-n7 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .my-xxxl-n8 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }
  .my-xxxl-n9 {
    margin-top: -11rem !important;
    margin-bottom: -11rem !important;
  }
  .my-xxxl-n11 {
    margin-top: -20rem !important;
    margin-bottom: -20rem !important;
  }
  .my-xxxl-n10 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }
  .mt-xxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxxl-n5 {
    margin-top: -3rem !important;
  }
  .mt-xxxl-n6 {
    margin-top: -4.5rem !important;
  }
  .mt-xxxl-n7 {
    margin-top: -6rem !important;
  }
  .mt-xxxl-n8 {
    margin-top: -8rem !important;
  }
  .mt-xxxl-n9 {
    margin-top: -11rem !important;
  }
  .mt-xxxl-n11 {
    margin-top: -20rem !important;
  }
  .mt-xxxl-n10 {
    margin-top: -14rem !important;
  }
  .me-xxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxxl-n5 {
    margin-right: -3rem !important;
  }
  .me-xxxl-n6 {
    margin-right: -4.5rem !important;
  }
  .me-xxxl-n7 {
    margin-right: -6rem !important;
  }
  .me-xxxl-n8 {
    margin-right: -8rem !important;
  }
  .me-xxxl-n9 {
    margin-right: -11rem !important;
  }
  .me-xxxl-n11 {
    margin-right: -20rem !important;
  }
  .me-xxxl-n10 {
    margin-right: -14rem !important;
  }
  .mb-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .mb-xxxl-n6 {
    margin-bottom: -4.5rem !important;
  }
  .mb-xxxl-n7 {
    margin-bottom: -6rem !important;
  }
  .mb-xxxl-n8 {
    margin-bottom: -8rem !important;
  }
  .mb-xxxl-n9 {
    margin-bottom: -11rem !important;
  }
  .mb-xxxl-n11 {
    margin-bottom: -20rem !important;
  }
  .mb-xxxl-n10 {
    margin-bottom: -14rem !important;
  }
  .ms-xxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxxl-n5 {
    margin-left: -3rem !important;
  }
  .ms-xxxl-n6 {
    margin-left: -4.5rem !important;
  }
  .ms-xxxl-n7 {
    margin-left: -6rem !important;
  }
  .ms-xxxl-n8 {
    margin-left: -8rem !important;
  }
  .ms-xxxl-n9 {
    margin-left: -11rem !important;
  }
  .ms-xxxl-n11 {
    margin-left: -20rem !important;
  }
  .ms-xxxl-n10 {
    margin-left: -14rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .p-xxxl-6 {
    padding: 4.5rem !important;
  }
  .p-xxxl-7 {
    padding: 6rem !important;
  }
  .p-xxxl-8 {
    padding: 8rem !important;
  }
  .p-xxxl-9 {
    padding: 11rem !important;
  }
  .p-xxxl-11 {
    padding: 20rem !important;
  }
  .p-xxxl-10 {
    padding: 14rem !important;
  }
  .px-xxxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .px-xxxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important;
  }
  .px-xxxl-7 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .px-xxxl-8 {
    padding-right: 8rem !important;
    padding-left: 8rem !important;
  }
  .px-xxxl-9 {
    padding-right: 11rem !important;
    padding-left: 11rem !important;
  }
  .px-xxxl-11 {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .px-xxxl-10 {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
  }
  .py-xxxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxxl-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .py-xxxl-8 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
  .py-xxxl-9 {
    padding-top: 11rem !important;
    padding-bottom: 11rem !important;
  }
  .py-xxxl-11 {
    padding-top: 20rem !important;
    padding-bottom: 20rem !important;
  }
  .py-xxxl-10 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }
  .pt-xxxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pt-xxxl-6 {
    padding-top: 4.5rem !important;
  }
  .pt-xxxl-7 {
    padding-top: 6rem !important;
  }
  .pt-xxxl-8 {
    padding-top: 8rem !important;
  }
  .pt-xxxl-9 {
    padding-top: 11rem !important;
  }
  .pt-xxxl-11 {
    padding-top: 20rem !important;
  }
  .pt-xxxl-10 {
    padding-top: 14rem !important;
  }
  .pe-xxxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pe-xxxl-6 {
    padding-right: 4.5rem !important;
  }
  .pe-xxxl-7 {
    padding-right: 6rem !important;
  }
  .pe-xxxl-8 {
    padding-right: 8rem !important;
  }
  .pe-xxxl-9 {
    padding-right: 11rem !important;
  }
  .pe-xxxl-11 {
    padding-right: 20rem !important;
  }
  .pe-xxxl-10 {
    padding-right: 14rem !important;
  }
  .pb-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pb-xxxl-6 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxxl-7 {
    padding-bottom: 6rem !important;
  }
  .pb-xxxl-8 {
    padding-bottom: 8rem !important;
  }
  .pb-xxxl-9 {
    padding-bottom: 11rem !important;
  }
  .pb-xxxl-11 {
    padding-bottom: 20rem !important;
  }
  .pb-xxxl-10 {
    padding-bottom: 14rem !important;
  }
  .ps-xxxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxxl-5 {
    padding-left: 3rem !important;
  }
  .ps-xxxl-6 {
    padding-left: 4.5rem !important;
  }
  .ps-xxxl-7 {
    padding-left: 6rem !important;
  }
  .ps-xxxl-8 {
    padding-left: 8rem !important;
  }
  .ps-xxxl-9 {
    padding-left: 11rem !important;
  }
  .ps-xxxl-11 {
    padding-left: 20rem !important;
  }
  .ps-xxxl-10 {
    padding-left: 14rem !important;
  }
  .text-xxxl-start {
    text-align: left !important;
  }
  .text-xxxl-end {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.5rem !important;
  }
  .fs-2 {
    font-size: 2.625rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

.border-dotted {
  border: 2px dotted rgba(0, 0, 0, 0.1) !important;
}

.border-dotted-0 {
  border: 0 !important;
}

.w-40 {
  width: 40% !important;
}

.h-400 {
  height: 400px !important;
}

.h-500 {
  height: 500px !important;
}

.h-600 {
  height: 600px !important;
}

.h-700 {
  height: 700px !important;
}

.h-800 {
  height: 800px !important;
}

.h-900 {
  height: 900px !important;
}

.vh-100 {
  height: 100vh !important;
}

@media (min-width: 576px) {
  .h-sm-400 {
    height: 400px !important;
  }
  .h-sm-500 {
    height: 500px !important;
  }
  .h-sm-600 {
    height: 600px !important;
  }
  .h-sm-700 {
    height: 700px !important;
  }
  .h-sm-800 {
    height: 800px !important;
  }
  .h-sm-900 {
    height: 900px !important;
  }
  .vh-sm-100 {
    height: 100vh !important;
  }
}

@media (min-width: 768px) {
  .h-md-400 {
    height: 400px !important;
  }
  .h-md-500 {
    height: 500px !important;
  }
  .h-md-600 {
    height: 600px !important;
  }
  .h-md-700 {
    height: 700px !important;
  }
  .h-md-800 {
    height: 800px !important;
  }
  .h-md-900 {
    height: 900px !important;
  }
  .vh-md-100 {
    height: 100vh !important;
  }
}

@media (min-width: 992px) {
  .h-lg-400 {
    height: 400px !important;
  }
  .h-lg-500 {
    height: 500px !important;
  }
  .h-lg-600 {
    height: 600px !important;
  }
  .h-lg-700 {
    height: 700px !important;
  }
  .h-lg-800 {
    height: 800px !important;
  }
  .h-lg-900 {
    height: 900px !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
}

@media (min-width: 1200px) {
  .h-xl-400 {
    height: 400px !important;
  }
  .h-xl-500 {
    height: 500px !important;
  }
  .h-xl-600 {
    height: 600px !important;
  }
  .h-xl-700 {
    height: 700px !important;
  }
  .h-xl-800 {
    height: 800px !important;
  }
  .h-xl-900 {
    height: 900px !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
}

@media (min-width: 1400px) {
  .h-xxl-400 {
    height: 400px !important;
  }
  .h-xxl-500 {
    height: 500px !important;
  }
  .h-xxl-600 {
    height: 600px !important;
  }
  .h-xxl-700 {
    height: 700px !important;
  }
  .h-xxl-800 {
    height: 800px !important;
  }
  .h-xxl-900 {
    height: 900px !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
}

@media (min-width: 1600px) {
  .h-xxxl-400 {
    height: 400px !important;
  }
  .h-xxxl-500 {
    height: 500px !important;
  }
  .h-xxxl-600 {
    height: 600px !important;
  }
  .h-xxxl-700 {
    height: 700px !important;
  }
  .h-xxxl-800 {
    height: 800px !important;
  }
  .h-xxxl-900 {
    height: 900px !important;
  }
  .vh-xxxl-100 {
    height: 100vh !important;
  }
}

.accordion .accordion-button {
  text-align: left;
}

.accordion.accordion-icon .accordion-header {
  position: relative;
}

.accordion.accordion-icon .accordion-header .accordion-button {
  background: transparent;
  color: #19191c;
  font-size: inherit;
  border: none;
  padding-left: 1.7rem;
}

.accordion.accordion-icon .accordion-header .accordion-button:after {
  content: "";
  background: #19191c;
  width: 10px;
  height: 2px;
  display: block;
  position: absolute;
  top: 45%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  transform: rotateZ(0deg);
}

.accordion.accordion-icon .accordion-header .accordion-button:before {
  content: "";
  background: #19191c;
  width: 10px;
  height: 2px;
  display: block;
  position: absolute;
  top: 45%;
  left: 0;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  transform: rotateZ(0deg);
}

.accordion.accordion-icon .accordion-header .accordion-button.collapsed:after {
  transform: rotateZ(90deg);
}

.accordion.accordion-icon .accordion-header .accordion-button.collapsed:before {
  transform: rotateZ(0deg);
}

.accordion.accordion-icon .accordion-collapse {
  border: none;
}

.accordion.accordion-icon .accordion-body {
  padding: 0.2rem 0.5rem 0.5rem 1.7rem;
}

.accordion.accordion-icon .accordion-item {
  border: none;
}

.accordion.accordion-icon .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion.accordion-line .accordion-header {
  position: relative;
}

.accordion.accordion-line .accordion-header .accordion-button {
  background: transparent;
  color: #19191c;
  font-size: inherit;
  border: none;
}

.accordion.accordion-line .accordion-header .accordion-button:after {
  content: "";
  background: #dfe0e5;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.accordion.accordion-line .accordion-header .accordion-button:before {
  content: "";
  background: #ea110c;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.accordion.accordion-line .accordion-header .accordion-button.collapsed:before {
  width: 0;
}

.accordion.accordion-line .accordion-header .accordion-button.collapsed:after {
  transform: rotate(180deg);
}

.accordion.accordion-line .accordion-collapse {
  border: none;
}

.accordion.accordion-line .accordion-item {
  border: none;
}

.accordion.accordion-line .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion.accordion-primary .accordion-header {
  position: relative;
  margin-bottom: 5px;
}

.accordion.accordion-primary .accordion-header .accordion-button {
  background: #ea110c;
  color: #fff;
  font-size: inherit;
  border: none;
}

.accordion.accordion-primary .accordion-header .accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion.accordion-primary .accordion-header .accordion-button.collapsed {
  background: #f0f1f3;
  color: #19191c;
}

.accordion.accordion-primary .accordion-header .accordion-button.collapsed:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2319191c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion.accordion-primary .accordion-collapse {
  border: none;
}

.accordion.accordion-primary .accordion-item {
  border: none;
}

.accordion.accordion-primary .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.breadcrumb-item {
  font-size: 0.8125rem;
}

.breadcrumb-item a {
  color: #515155;
}

.breadcrumb-item a:hover {
  color: #ea110c;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Font Awesome 5 Free";
  font-size: 6px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.breadcrumb.breadcrumb-dark .breadcrumb-item a {
  color: #dfe0e5;
}

.breadcrumb.breadcrumb-dark .breadcrumb-item a:hover {
  color: #ea110c;
}

.btn {
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;
  outline: 0;
  white-space: nowrap;
}

.btn-xs {
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: 0;
}

.btn-white {
  background: #fff;
  color: #19191c;
}

.btn-white.active, .btn-white:active, .btn-white:focus, .btn-white:hover {
  background: #f0f1f3;
  color: #19191c;
}

.btn-outline-white {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.btn-outline-white.active, .btn-outline-white:active, .btn-outline-white:focus, .btn-outline-white:hover {
  color: #19191c;
  background: #fff;
}

.btn-outline-light {
  background: transparent;
  border-color: #d0d4d9;
  color: #a1a1a8;
}

.btn-outline-light:hover {
  background: #d0d4d9;
  border-color: #d0d4d9;
  color: #19191c;
}

.btn-round {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  padding: 0;
  text-align: center;
}

.btn-round i {
  margin: 0;
}

.btn-round .fa-play {
  padding-left: 3px;
}

.btn-round.btn-lg, .btn-group-lg > .btn-round.btn {
  height: 55px;
  width: 55px;
  line-height: 55px;
}

.btn-round.btn-sm, .btn-group-sm > .btn-round.btn {
  height: 32px;
  width: 32px;
  line-height: 30px;
}

.btn-round.btn-sm i, .btn-group-sm > .btn-round.btn i {
  font-size: .6rem;
}

/*rtl:raw:
.btn-round .fa-play{
  padding-left: 3px;
  padding-right: 0;
}
*/
.btn-line {
  padding: 0.5rem 1rem 0.5rem 2.2rem;
}

.btn-line:before {
  content: "";
  width: 16px;
  height: 2px;
  display: block;
  background: #a1a1a8;
  position: absolute;
  left: 15px;
  bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.btn-line:after {
  content: "";
  width: 16px;
  height: 2px;
  display: block;
  background: #a1a1a8;
  position: absolute;
  right: -16px;
  bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.btn-line:hover {
  padding: 0.5rem 2.2rem 0.5rem 1rem;
}

.btn-line:hover:before {
  right: auto;
  left: -16px;
}

.btn-line:hover:after {
  right: 16px;
  left: auto;
  transition: all 0.3s ease-in-out;
  animation: blink-animation 1s steps(5, start) infinite;
}

.btn-line.btn-primary:before {
  background: #fff;
}

.btn-line.btn-primary:after {
  background: #fff;
}

.btn-line.btn-secondary:before {
  background: #000;
}

.btn-line.btn-secondary:after {
  background: #000;
}

.btn-line.btn-success:before {
  background: #000;
}

.btn-line.btn-success:after {
  background: #000;
}

.btn-line.btn-info:before {
  background: #000;
}

.btn-line.btn-info:after {
  background: #000;
}

.btn-line.btn-warning:before {
  background: #000;
}

.btn-line.btn-warning:after {
  background: #000;
}

.btn-line.btn-danger:before {
  background: #fff;
}

.btn-line.btn-danger:after {
  background: #fff;
}

.btn-line.btn-light:before {
  background: #000;
}

.btn-line.btn-light:after {
  background: #000;
}

.btn-line.btn-dark:before {
  background: #fff;
}

.btn-line.btn-dark:after {
  background: #fff;
}

.btn-line.btn-white:before, .btn-line.btn-white:after {
  background: #19191c;
}

.btn-line.text-white {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-white:before {
  background: #fff;
  left: 0;
}

.btn-line.text-white:after {
  background: #fff;
  right: -16px;
}

.btn-line.text-white:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-white:hover:before {
  left: -16px;
}

.btn-line.text-white:hover:after {
  right: 0;
}

.btn-line.text-primary {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-primary:before {
  background: #ea110c;
  left: 0;
}

.btn-line.text-primary:after {
  background: #ea110c;
  right: -16px;
}

.btn-line.text-primary:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-primary:hover:before {
  left: -16px;
}

.btn-line.text-primary:hover:after {
  right: 0;
}

.btn-line.text-secondary {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-secondary:before {
  background: #d0d4d9;
  left: 0;
}

.btn-line.text-secondary:after {
  background: #d0d4d9;
  right: -16px;
}

.btn-line.text-secondary:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-secondary:hover:before {
  left: -16px;
}

.btn-line.text-secondary:hover:after {
  right: 0;
}

.btn-line.text-success {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-success:before {
  background: #28a745;
  left: 0;
}

.btn-line.text-success:after {
  background: #28a745;
  right: -16px;
}

.btn-line.text-success:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-success:hover:before {
  left: -16px;
}

.btn-line.text-success:hover:after {
  right: 0;
}

.btn-line.text-info {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-info:before {
  background: #17a2b8;
  left: 0;
}

.btn-line.text-info:after {
  background: #17a2b8;
  right: -16px;
}

.btn-line.text-info:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-info:hover:before {
  left: -16px;
}

.btn-line.text-info:hover:after {
  right: 0;
}

.btn-line.text-warning {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-warning:before {
  background: #ffc107;
  left: 0;
}

.btn-line.text-warning:after {
  background: #ffc107;
  right: -16px;
}

.btn-line.text-warning:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-warning:hover:before {
  left: -16px;
}

.btn-line.text-warning:hover:after {
  right: 0;
}

.btn-line.text-danger {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-danger:before {
  background: #dc3545;
  left: 0;
}

.btn-line.text-danger:after {
  background: #dc3545;
  right: -16px;
}

.btn-line.text-danger:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-danger:hover:before {
  left: -16px;
}

.btn-line.text-danger:hover:after {
  right: 0;
}

.btn-line.text-light {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-light:before {
  background: #f7f8f9;
  left: 0;
}

.btn-line.text-light:after {
  background: #f7f8f9;
  right: -16px;
}

.btn-line.text-light:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-light:hover:before {
  left: -16px;
}

.btn-line.text-light:hover:after {
  right: 0;
}

.btn-line.text-dark {
  padding: 0.5rem 0rem 0.5rem 1.3rem;
}

.btn-line.text-dark:before {
  background: #19191c;
  left: 0;
}

.btn-line.text-dark:after {
  background: #19191c;
  right: -16px;
}

.btn-line.text-dark:hover {
  padding: 0.5rem 1.3rem 0.5rem 0rem;
}

.btn-line.text-dark:hover:before {
  left: -16px;
}

.btn-line.text-dark:hover:after {
  right: 0;
}

.btn-line.btn-sm:before, .btn-group-sm > .btn-line.btn:before {
  bottom: 12px;
}

.btn-line.btn-sm:after, .btn-group-sm > .btn-line.btn:after {
  bottom: 12px;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.btn-ripple {
  position: relative;
  overflow: visible;
}

.btn-ripple:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-color: inherit;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 1;
  animation-name: rippleanim;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.3, 1);
  animation-delay: 1s;
}

@keyframes rippleanim {
  0% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(234, 17, 12, 0.9);
  }
  100% {
    opacity: 0;
    box-shadow: 0 0 0 15px rgba(234, 17, 12, 0.4);
  }
}

@-webkit-keyframes rippleanim {
  0% {
    opacity: 1;
    background: rgba(234, 17, 12, 0.9);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    background: rgba(234, 17, 12, 0.4);
    transform: scale(1.5);
  }
}

.card-metro {
  overflow: hidden;
}

.card-metro .card-img-overlay {
  z-index: 2;
}

.card-metro .card-text {
  transition: all 0.3s ease-in-out;
}

.card-metro img {
  transition: all 0.3s ease-in-out;
}

.card-metro:before {
  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.8)));
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
  z-index: 1;
}

.card-metro:hover .card-text {
  margin-bottom: 10px;
}

.card-metro:hover .card-image img {
  transform: scale(1.08);
}

.card .card-element-hover {
  visibility: hidden;
  margin-top: 30px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}

.card:hover .card-element-hover {
  visibility: visible;
  margin-top: 0px;
  opacity: 1;
}

.card:hover .hover-overlay-bottom:before {
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  bottom: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.8)));
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
  z-index: 9;
}

.card:hover .hover-overlay-top:before {
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  top: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.8)));
  background-image: linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.8));
  z-index: 9;
}

.dropdown-menu {
  box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.05), 0px 0px 30px rgba(83, 88, 93, 0.1);
}

.dropdown-item {
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}

.dropdown-item.active {
  color: #ea110c !important;
}

.dropdown-toggle:after {
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropend .dropdown-toggle:after {
  content: "\f054";
  font-family: 'Font Awesome 5 Free';
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropstart .dropdown-toggle:before {
  content: "\f053";
  font-family: 'Font Awesome 5 Free';
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropup .dropdown-toggle:after {
  content: "\f077";
  font-family: 'Font Awesome 5 Free';
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropdown-menu .dropdown-toggle:after {
  position: absolute;
  right: 25px;
  top: 10px;
}

.dropdown-menu .dropdown-toggle:before {
  position: absolute;
  right: 25px;
  top: 10px;
}

.dropdown-header {
  font-weight: 700;
  padding-top: 0.5rem;
  font-size: 0.875rem;
}

.dropdown-fullwidth {
  position: static;
}

.dropdown-fullwidth .dropdown-menu {
  margin: 0 auto;
  left: 0;
  right: 0;
  padding: 1rem 0;
}

@media (min-width: 576px) {
  .dropdown-fullwidth .dropdown-menu {
    max-width: 96%;
  }
}

@media (min-width: 768px) {
  .dropdown-fullwidth .dropdown-menu {
    max-width: 94%;
  }
}

@media (min-width: 992px) {
  .dropdown-fullwidth .dropdown-menu {
    max-width: 94%;
  }
}

@media (min-width: 1200px) {
  .dropdown-fullwidth .dropdown-menu {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .dropdown-fullwidth .dropdown-menu {
    max-width: 1320px;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-fullwidth .dropdown-menu .container {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-xl .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-xl .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-lg .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-lg .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-md .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-md .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-sm .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-sm .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}

.dropdown-toggle-start-icon .dropdown-toggle {
  position: absolute;
  top: 20px;
  left: -40px;
  background: rgba(234, 17, 12, 0.2);
  border: none;
  width: 34px;
  height: 34px;
  line-height: 34px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
}

.dropdown-toggle-start-icon .collapsed.dropdown-toggle:after {
  content: "\f00d";
  color: #ea110c;
  font-family: 'Font Awesome 5 Free';
  font-size: 14px;
  top: 5px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}

.dropdown-toggle-start-icon .dropdown-toggle:after {
  content: "\f00d";
  color: #ea110c;
  font-family: 'Font Awesome 5 Free';
  font-size: 14px;
  top: 5px;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.form-line .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-left: 0;
  border-radius: 0;
}

.form-line .form-control:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background: #ea110c;
  display: block;
}

.form-line .form-control:focus {
  background-color: transparent;
  border-color: #dfe0e5;
}

.form-line .input-group {
  display: block;
  position: relative;
}

.form-line .form-control ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 1px;
  background-color: #ea110c;
  transition: all 0.6s cubic-bezier(0.42, 0, 0, 0.97);
}

.form-line input.form-control:focus ~ .focus-border,
.form-line textarea.form-control:focus ~ .focus-border {
  width: 100%;
}

.form-dark .form-control {
  background: transparent;
  border-color: #29292e;
  color: #a1a1a8;
}

.form-dark .form-control:focus {
  color: #fff;
  border-color: #515155;
  outline: 0;
}

.form-dark.form-line .form-control:focus {
  border-color: #19191c;
}

.form-primary .form-control {
  background: transparent;
  border-color: #fff;
  color: #fff;
}

.form-primary .form-control:focus {
  color: #fff;
  background-color: transparent;
  border-color: #19191c;
  outline: 0;
}

.form-primary .form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

.form-primary.form-line .form-control:focus {
  border-color: white;
}

.form-primary.form-line .form-control ~ .focus-border {
  background-color: #19191c;
}

.caret-primary {
  caret-color: #ea110c;
}

.input-text-white-stroke input {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem !important;
  font-weight: bold;
}

.input-text-white-stroke input::-webkit-input-placeholder {
  color: transparent;
  opacity: 0.2;
}

@media (min-width: 768px) {
  .input-text-white-stroke input {
    font-size: 3rem !important;
  }
}

.img-cover {
  object-fit: cover;
}

.img-grayscale:not(img) {
  background-blend-mode: luminosity;
  background-color: #29292e !important;
}

.img-grayscale [id*='jarallax-container-'] > div {
  background-blend-mode: luminosity;
  background-color: #000;
}

img.img-grayscale {
  filter: grayscale(100%);
}

.list-group .list-group-item i, .list-group .list-group-item span {
  margin-right: 5px;
}

.list-group-borderless .list-group-item {
  border: none;
  padding: 0.425rem 0;
}

.list-group-borderless a.list-group-item:hover {
  background-color: transparent;
  color: #ea110c;
}

.list-group-inline {
  display: block;
}

.list-group-inline li {
  border: none;
  display: inline-block;
  padding: 0.425rem 0;
}

.list-group-inline .list-group-item {
  border: none;
  display: inline-block;
  padding: 0.425rem 0;
  margin-right: 30px;
  width: auto;
}

.list-group-inline.list-group-icon-primary-bg .list-group-item {
  display: inline-flex;
}

.list-group-item-action:hover {
  background: transparent;
  color: #ea110c;
}

.list-group-item-action:focus {
  background: transparent;
  color: #ea110c;
}

.list-group-icon-primary-bg .list-group-item {
  display: flex;
}

.list-group-icon-primary-bg i, .list-group-icon-primary-bg span {
  background: #f58886;
  color: #ea110c;
  font-weight: 700;
  height: 22px;
  width: 22px;
  line-height: 23px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px !important;
  font-size: 11px;
  flex-shrink: 0;
  justify-content: center;
}

.nav-pills .nav-link {
  cursor: pointer;
  margin-bottom: 15px;
  color: #29292e;
  padding: 0.5rem 1rem;
}

.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}

.tab-content {
  padding: 25px 0;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  color: #19191c;
  padding: 0.5rem 1rem;
  border: none;
}

.nav-tabs .nav-link.active {
  background: #ea110c;
  color: #fff;
  border: none;
}

.nav-tabs .nav-link.active .nav-link {
  color: #fff;
}

.nav-tabs.nav-tabs-line {
  border-bottom: solid 2px #f0f1f3;
}

.nav-tabs.nav-tabs-line .nav-link {
  border: transparent;
}

.nav-tabs.nav-tabs-line .nav-item:first-child .nav-link {
  padding-left: 0;
}

.nav-tabs.nav-tabs-line .nav-link {
  background: transparent;
  border-bottom: 2px solid transparent;
}

.nav-tabs.nav-tabs-line .nav-link.active {
  background: transparent;
  color: #ea110c;
  border-bottom: 2px solid #ea110c;
}

.nav-tabs.nav-tabs-line .nav-item {
  margin-bottom: -2px;
}

.nav-tabs.nav-tabs-bordered {
  border: 1px solid #f0f1f3;
}

.nav-tabs.nav-tabs-bordered .nav-link {
  border: transparent;
  border-radius: 0;
}

.nav-tabs.nav-tabs-bordered .nav-link.active {
  background: #ea110c;
  color: #fff;
  border-radius: 0;
}

.nav-tabs.nav-tabs-dark .nav-item .nav-link {
  color: #19191c;
}

.nav-tabs.nav-tabs-dark .nav-link.active {
  background: #19191c;
  color: #fff;
}

.navbar {
  z-index: 9;
}

.navbar-nav .nav-link {
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 90px;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-toggler {
  margin-bottom: 0;
  border: 0;
}

header.navbar-sticky-on {
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  animation: fadeInDown .5s;
  width: 100%;
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.2);
}

header.navbar-sticky-on .navbar-top {
  display: none !important;
}

header.navbar-sticky-on .navbar-nav .nav-link {
  line-height: 50px;
}

header.navbar-sticky-on .navbar-brand {
  padding-top: 14px;
  padding-bottom: 14px;
}

header.navbar-sticky-on .navbar-brand .navbar-brand-item {
  height: 22px;
}

.navbar-top {
  padding: .2rem 0;
}

.navbar-top span {
  font-size: 0.8125rem;
  color: #515155;
}

.navbar-top a {
  color: #515155;
  font-size: 0.8125rem;
}

.navbar-top a:hover {
  color: #ea110c;
}

.navbar-space-lg:not(.navbar-sticky-on) .navbar-expand {
  padding: 1.5rem !important;
}

@media (max-width: 767.98px) {
  .navbar-space-lg:not(.navbar-sticky-on) .navbar-expand {
    padding: 0.5rem 0 !important;
  }
}

.navbar-transparent {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-light .nav .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .nav .nav-link:hover {
  color: #ea110c;
}

.navbar-dark .navbar-top span:not(.dropdown-item-text) {
  color: #fff;
}

.navbar-dark .navbar-top a:not(.dropdown-item) {
  color: #fff;
}

.navbar-dark .navbar-top a:not(.dropdown-item):hover {
  color: #ea110c;
}

.navbar-dark .nav .nav-link {
  color: #fff;
}

.navbar-dark .nav .nav-link:hover {
  color: #ea110c;
}

.navbar-dark.navbar-sticky-on {
  background-color: #19191c;
}

.navbar-floating {
  background: transparent;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.navbar-floating .navbar > .container {
  background: #fff;
  padding: 0 30px !important;
  border-radius: 0;
  box-shadow: 0px 20px 0px -14px rgba(255, 255, 255, 0.3), 0px 40px 0px -28px rgba(255, 255, 255, 0.3);
}

.navbar-floating.navbar-sticky-on .navbar > .container {
  box-shadow: none;
  padding: 0 15px !important;
}

@media (max-width: 767.98px) {
  .navbar-floating {
    top: 20px;
  }
  .navbar-floating .navbar > .container {
    padding: 0 15px !important;
  }
}

.navbar-brand {
  padding-top: 30px;
  padding-bottom: 30px;
}

.navbar-brand .navbar-brand-item {
  height: 30px;
  display: block;
  width: auto;
}

@media (max-width: 767.98px) {
  .navbar-brand {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar-brand .navbar-brand-item {
    height: 22px;
  }
}

.navbar .container-fluid .dropdown-fullwidth .dropdown-menu, .navbar .container-sm .dropdown-fullwidth .dropdown-menu, .navbar .container-md .dropdown-fullwidth .dropdown-menu, .navbar .container-lg .dropdown-fullwidth .dropdown-menu, .navbar .container-xl .dropdown-fullwidth .dropdown-menu, .navbar .container-xxl .dropdown-fullwidth .dropdown-menu {
  width: 100%;
}

.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-toggle:after {
  content: "\f00d";
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  top: 8px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
  border: none !important;
  margin: 0;
}

@media (hover: hover) {
  .navbar .dropdown-menu .dropdown-submenu.dropend:hover > .dropdown-toggle:after {
    transform: rotate(90deg);
  }
}

.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-opened.dropdown-toggle:after {
  transform: rotate(90deg);
}

.navbar .dropdown-menu .dropdown-submenu.dropstart .dropdown-toggle:before {
  content: "\f00d";
  font-family: 'Font Awesome 5 Free';
  font-size: 10px;
  top: 8px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
  border: none !important;
  margin: 0;
  font-weight: 900;
}

.navbar .dropdown-menu .dropdown-submenu.dropstart:hover > .dropdown-toggle:before {
  transform: rotate(90deg);
}

.navbar-link-xl .navbar-nav .nav-link {
  font-size: 1.2rem;
}

.navbar-link-xl .dropdown-toggle:after {
  font-size: 0.7rem;
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-toggle::before {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-dark .navbar-expand-xl .navbar-collapse .nav-link {
    color: #515155;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-xl .dropdown-fullwidth {
    width: 100%;
  }
  .navbar-expand-xl .dropdown-fullwidth .dropdown-menu {
    max-width: 100%;
  }
  .navbar-expand-xl .dropdown-fullwidth .container {
    max-width: 100%;
    padding: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item > .dropdown-menu {
    background: #f7f8f9;
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(0, 0, 0, 0.04);
    padding: 20px 0px !important;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-toggle::before {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-dark .navbar-expand-lg .navbar-collapse .nav-link {
    color: #515155;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-lg .dropdown-fullwidth {
    width: 100%;
  }
  .navbar-expand-lg .dropdown-fullwidth .dropdown-menu {
    max-width: 100%;
  }
  .navbar-expand-lg .dropdown-fullwidth .container {
    max-width: 100%;
    padding: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu {
    background: #f7f8f9;
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(0, 0, 0, 0.04);
    padding: 20px 0px !important;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse {
    box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-toggle::before {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-dark .navbar-expand-md .navbar-collapse .nav-link {
    color: #515155;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-md .dropdown-fullwidth {
    width: 100%;
  }
  .navbar-expand-md .dropdown-fullwidth .dropdown-menu {
    max-width: 100%;
  }
  .navbar-expand-md .dropdown-fullwidth .container {
    max-width: 100%;
    padding: 0;
  }
  .navbar-expand-md .navbar-nav .nav-item > .dropdown-menu {
    background: #f7f8f9;
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-md .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(0, 0, 0, 0.04);
    padding: 20px 0px !important;
  }
}

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-toggle::before {
    position: absolute;
    right: 30px;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu {
    box-shadow: none;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-dark .navbar-expand-sm .navbar-collapse .nav-link {
    color: #515155;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-sm .dropdown-fullwidth {
    width: 100%;
  }
  .navbar-expand-sm .dropdown-fullwidth .dropdown-menu {
    max-width: 100%;
  }
  .navbar-expand-sm .dropdown-fullwidth .container {
    max-width: 100%;
    padding: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item > .dropdown-menu {
    background: #f7f8f9;
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(0, 0, 0, 0.04);
    padding: 20px 0px !important;
  }
}

.pagination .page-item {
  display: inline-block;
  margin-bottom: 5px;
}

.pagination.pagination-line li.disabled a:before {
  background: #d0d4d9 !important;
}

.pagination.pagination-line li.disabled:hover a:before {
  animation: none !important;
}

.pagination.pagination-line li:first-child a {
  padding-left: 36px;
}

.pagination.pagination-line li:first-child a:before {
  content: "";
  width: 16px;
  height: 2px;
  display: block;
  background: #515155;
  position: absolute;
  left: 15px;
  bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.pagination.pagination-line li:first-child:hover a:before {
  background: #fff;
  animation: blink-animation 1s steps(5, start) infinite;
}

.pagination.pagination-line li:last-child a {
  padding-right: 36px;
}

.pagination.pagination-line li:last-child a:after {
  content: "";
  width: 16px;
  height: 2px;
  display: block;
  background: #515155;
  position: absolute;
  right: 15px;
  bottom: 15px;
  transition: all 0.3s ease-in-out;
}

.pagination.pagination-line li:last-child:hover a:after {
  background: #fff;
  animation: blink-animation 1s steps(5, start) infinite;
}

.progress {
  position: relative;
  overflow-x: visible;
  overflow-y: visible;
}

.progress .progress-bar {
  position: relative;
  overflow: visible;
  border-radius: 0;
}

.progress .progress-percent {
  color: #515155;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  right: 0;
  top: -24px;
  font-size: 12px;
}

.progress.progress-percent-bg .progress-percent {
  background: #19191c;
  padding: 2px 5px;
  border-radius: 2px;
  color: #fff;
  line-height: 16px;
  position: absolute;
  right: 0;
  top: -25px;
  font-size: 10px;
}

.progress.progress-percent-bg .progress-percent:before {
  border-top-color: #19191c;
  border-width: 5px;
  left: 50%;
  margin-left: -5px;
}

.progress.progress-percent-bg .progress-percent:after, .progress.progress-percent-bg .progress-percent:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.progress.progress-percent-bg .progress-percent:after {
  border-top-color: #19191c;
  border-width: 4px;
  left: 50%;
  margin-left: -4px;
}

.progress.progress-xs {
  height: 3px;
}

.progress.progress-sm {
  height: 5px;
}

.progress.progress-md {
  height: 10px;
}

.progress.progress-lg {
  height: 16px;
}

.progress.progress-xl {
  height: 20px;
}

html {
  overflow-x: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  z-index: 0;
  position: relative;
}

main {
  background-color: #fff;
}

header {
  position: relative;
  z-index: 1020;
  background: #fff;
}

b,
strong {
  font-weight: 700;
}

a {
  transition: all 0.3s ease-in-out;
}

h1 > a, .h1 > a,
h2 > a,
.h2 > a,
h3 > a,
.h3 > a,
h4 > a,
.h4 > a,
h5 > a,
.h5 > a,
h6 > a,
.h6 > a,
.h1 > a,
.h2 > a,
.h3 > a,
.h4 > a,
.h5 > a,
.h6 > a {
  color: #19191c;
}

h1 > a:hover, .h1 > a:hover,
h2 > a:hover,
.h2 > a:hover,
h3 > a:hover,
.h3 > a:hover,
h4 > a:hover,
.h4 > a:hover,
h5 > a:hover,
.h5 > a:hover,
h6 > a:hover,
.h6 > a:hover,
.h1 > a:hover,
.h2 > a:hover,
.h3 > a:hover,
.h4 > a:hover,
.h5 > a:hover,
.h6 > a:hover {
  color: #ea110c;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

iframe {
  border: 0;
}

ul,
ol,
dl {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

section {
  padding-top: 6rem;
  padding-bottom: 4.8rem;
}

@media (max-width: 767.98px) {
  section {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}

*:focus {
  outline: none !important;
}

::selection {
  color: #fff;
  background: #ea110c;
}

.bg-primary::selection {
  color: #ea110c;
  background: #fff;
}

.text-white-force *:not(.btn) {
  color: #fff !important;
}

.text-primary-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ea110c;
  transition: all 0.3s ease-in-out;
}

.text-primary-stroke.text-hover-fill:hover,
a.text-primary-stroke:hover {
  color: #ea110c !important;
}

.text-white-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  transition: all 0.3s ease-in-out;
}

.text-white-stroke.text-hover-fill:hover,
a.text-white-stroke:hover {
  color: #fff !important;
}

.text-dark-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #19191c;
  transition: all 0.3s ease-in-out;
}

.text-dark-stroke.text-hover-fill:hover,
a.text-dark-stroke:hover {
  color: #19191c !important;
}

.text-light-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #dfe0e5;
  transition: all 0.3s ease-in-out;
}

.text-light-stroke.text-hover-fill:hover,
a.text-light-stroke:hover {
  color: #dfe0e5 !important;
}

.text-stroke-md {
  -webkit-text-stroke-width: 2px;
}

.text-stroke-lg {
  -webkit-text-stroke-width: 3px;
}

.text-primary-shadow {
  text-shadow: 0.03em 0.03em 0 #ea110c;
}

.text-dark-shadow {
  text-shadow: 0.03em 0.03em 0 #19191c;
}

.text-white-shadow {
  text-shadow: 0.03em 0.03em 0 #fff;
}

.text-light-shadow {
  text-shadow: 0.03em 0.03em 0 #dfe0e5;
}

small, .small,
.small {
  font-weight: 400;
}

.smaller {
  font-size: 60%;
  font-weight: 500;
}

.bg-facebook {
  background-color: #5d82d1;
  color: #fff;
}

.bg-facebook:hover {
  background-color: #5475bc;
  color: #fff;
}

.text-facebook {
  color: #5d82d1;
}

.text-facebook:hover {
  color: #5475bc;
}

.bg-instagram {
  background: #c22b72;
  color: #fff;
}

.bg-instagram:hover {
  background-color: #af2767;
  color: #fff;
}

.text-instagram {
  color: #c22b72;
}

.text-instagram:hover {
  color: #af2767;
}

.bg-instagram-gradient {
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  color: #fff;
  background-size: 120% 120%;
  background-position: right bottom;
  transition: all 0.3s ease-in-out;
  border: 0;
}

.bg-instagram-gradient:hover {
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  color: #fff;
  background-size: 100% 100%;
  transition: all 0.3s ease-in-out;
  background-position: left bottom;
}

.text-instagram-gradient {
  color: transparent;
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  transition: all 0.3s ease-in-out;
  background-size: 120% 120%;
}

.text-instagram-gradient:hover {
  color: transparent;
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 100% 100%;
  transition: all 0.3s ease-in-out;
}

.bg-google {
  background-color: #3c7ff1;
  color: #fff;
}

.bg-google:hover {
  background-color: #3672d9;
  color: #fff;
}

.text-google {
  color: #3c7ff1;
}

.text-google:hover {
  color: #3672d9;
}

.bg-twitter {
  background-color: #40bff5;
  color: #fff;
}

.bg-twitter:hover {
  background-color: #3aacdd;
  color: #fff;
}

.text-twitter {
  color: #40bff5;
}

.text-twitter:hover {
  color: #3aacdd;
}

.bg-linkedin {
  background-color: #238cc8;
  color: #fff;
}

.bg-linkedin:hover {
  background-color: #207eb4;
  color: #fff;
}

.text-linkedin {
  color: #238cc8;
}

.text-linkedin:hover {
  color: #207eb4;
}

.bg-pinterest {
  background-color: #e60023;
  color: #fff;
}

.bg-pinterest:hover {
  background-color: #cf0020;
  color: #fff;
}

.text-pinterest {
  color: #e60023;
}

.text-pinterest:hover {
  color: #cf0020;
}

.bg-dribbble {
  background-color: #f7659c;
  color: #fff;
}

.bg-dribbble:hover {
  background-color: #de5b8c;
  color: #fff;
}

.text-dribbble {
  color: #f7659c;
}

.text-dribbble:hover {
  color: #de5b8c;
}

.bg-youtube {
  background-color: #ff0000;
  color: #fff;
}

.bg-youtube:hover {
  background-color: #e60000;
  color: #fff;
}

.text-youtube {
  color: #ff0000;
}

.text-youtube:hover {
  color: #e60000;
}

.bg-skype {
  background-color: #13c1f3;
  color: #fff;
}

.bg-skype:hover {
  background-color: #11aedb;
  color: #fff;
}

.text-skype {
  color: #13c1f3;
}

.text-skype:hover {
  color: #11aedb;
}

@media (min-width: 576px) {
  .display-sm-1 {
    font-size: 8.75rem;
  }
  .display-sm-2 {
    font-size: 7.875rem;
  }
  .display-sm-3 {
    font-size: 6.125rem;
  }
  .display-sm-4 {
    font-size: 4.8125rem;
  }
  .display-sm-5 {
    font-size: 4rem;
  }
  .display-sm-6 {
    font-size: 3.2rem;
  }
  .display-sm-7 {
    font-size: 2.8rem;
  }
  .display-sm-8 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  [class*='display-md-'] {
    line-height: 1.2;
  }
  .display-md-1 {
    font-size: 8.75rem;
  }
  .display-md-2 {
    font-size: 7.875rem;
  }
  .display-md-3 {
    font-size: 6.125rem;
    line-height: 1.2;
  }
  .display-md-4 {
    font-size: 4.8125rem;
  }
  .display-md-5 {
    font-size: 4rem;
  }
  .display-md-6 {
    font-size: 3.2rem;
  }
  .display-md-7 {
    font-size: 2.8rem;
  }
  .display-md-8 {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  [class*='display-lg-'] {
    line-height: 1.4;
  }
  .display-lg-1 {
    font-size: 8.75rem;
  }
  .display-lg-2 {
    font-size: 7.875rem;
  }
  .display-lg-3 {
    font-size: 6.125rem;
  }
  .display-lg-4 {
    font-size: 4.8125rem;
  }
  .display-lg-5 {
    font-size: 4rem;
  }
  .display-lg-6 {
    font-size: 3.2rem;
  }
  .display-lg-7 {
    font-size: 2.8rem;
  }
  .display-lg-8 {
    font-size: 2rem;
  }
}

.blockquote {
  position: relative;
  padding-left: 80px;
}

.blockquote .blockquote-footer {
  margin-top: 12px;
}

.blockquote:before {
  content: '"';
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8rem;
  line-height: 0.8em;
  font-weight: 700;
  color: #ea110c;
}

.blockquote.blockquote-primary {
  background: #ea110c;
  color: #fff;
  padding: 20px 20px 20px 90px;
  border-radius: 0;
}

.blockquote.blockquote-primary * {
  color: #fff;
}

.blockquote.blockquote-primary:before {
  color: #fff !important;
  top: 20px;
  left: 20px;
}

.blockquote.blockquote-dark {
  background: #19191c;
  color: #fff;
  padding: 20px 20px 20px 90px;
  border-radius: 0;
}

.blockquote.blockquote-dark * {
  color: #fff;
}

.blockquote.blockquote-dark:before {
  top: 20px;
  left: 20px;
}

.blockquote.blockquote-light {
  background: #f0f1f3;
  color: #29292e;
  padding: 20px 20px 20px 90px;
  border-radius: 0;
}

.blockquote.blockquote-light * {
  color: #29292e;
}

.blockquote.blockquote-light:before {
  top: 20px;
  left: 20px;
}

.blockquote.blockquote-line {
  padding: 10px 20px;
  border-left: 5px solid #ea110c;
}

.blockquote.blockquote-line:before {
  display: none;
}

[data-bs-padding-right] {
  padding: 0 !important;
}

:root {
  --animate-delay: 0.5s;
}

.plyr__control--overlaid, .plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: #ea110c;
}

.plyr--full-ui input[type=range] {
  color: #ea110c;
}

/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/
.content__img {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  opacity: 0;
}

.ityped-cursor {
  color: #19191c;
  opacity: 1;
  animation: cursorblink 0.3s infinite;
  animation-direction: alternate;
}

@keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes cursorblink {
  100% {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .cursor-dot,
  .cursor-dot-outline {
    z-index: 1100;
    pointer-events: none;
    position: fixed;
    top: 50%;
    left: 50% /* rtl:ignore */;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  }
  .cursor-dot {
    width: 8px;
    height: 8px;
    background-color: #d30f0b;
  }
  .cursor-dot-outline {
    width: 30px;
    height: 30px;
    background-color: #ea110c;
    opacity: 0.4 !important;
  }
}

.search-full {
  height: 0px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1080;
}

.search-full.show {
  height: 100vh;
}

.scroll-down {
  width: 25px;
  right: 0px;
  position: absolute;
  bottom: 0;
  color: #19191c;
  z-index: 2;
}

.scroll-down.scroll-down-light {
  color: #fff;
}

.scroll-down.scroll-down-light .scroll-line:after {
  background: linear-gradient(180deg, #fff 50%, rgba(21, 175, 90, 0) 0);
  background-position: 0 -60px;
  background-size: 100% 200%;
}

.scroll-down .scoll-text {
  writing-mode: vertical-rl;
  margin-top: 8px;
}

.scroll-down .scroll-line {
  width: 1px;
  height: 60px;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
}

.scroll-down .scroll-line:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, #19191c 50%, rgba(21, 175, 90, 0) 0);
  background-position: 0 -60px;
  background-size: 100% 200%;
  -webkit-animation: scrolldown 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
  animation: scrolldown 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
}

@-webkit-keyframes scrolldown {
  0% {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  to {
    background-position: 0 60px;
  }
}

@keyframes scrolldown {
  0% {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  to {
    background-position: 0 60px;
  }
}

.back-top {
  cursor: pointer;
  position: fixed !important;
  bottom: 0;
  right: 0;
  display: block;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  transform: translateY(50%);
  transition: all 0.3s ease-in-out;
  background: #ea110c;
  color: #fff;
  padding: 20px 0 15px 0;
  width: 40px;
  position: absolute;
  color: #fff;
}

.back-top.back-top-show {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  transition: all 1s ease-in-out;
}

.back-top .scoll-text {
  writing-mode: vertical-rl;
  padding-left: 8px;
}

.back-top .scroll-line {
  width: 1px;
  height: 60px;
  overflow: hidden;
  margin: 0 0 10px 20px;
}

.back-top .scroll-line:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(180deg, #fff 50%, rgba(21, 175, 90, 0) 0);
  background-position: 0 -60px;
  background-size: 100% 200%;
  -webkit-animation: backtop 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
  animation: backtop 5s cubic-bezier(0.76, 0, 0.3, 1) infinite forwards;
}

@-webkit-keyframes backtop {
  from {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  0% {
    background-position: 0 60px;
  }
}

@keyframes backtop {
  from {
    background-position: 0 -60px;
  }
  75% {
    background-position: 0 0;
  }
  0% {
    background-position: 0 60px;
  }
}

/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/
.bg-parallax {
  -webkit-transform: translate3d(0, 0, 0);
}

.bg-parallax:before {
  z-index: 0;
}

.marquee-animation {
  animation: marqueeAnimation 20s linear infinite;
  position: absolute;
  display: block;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  white-space: nowrap;
}

.marquee-animation.hover {
  animation-play-state: paused;
}

@keyframes marqueeAnimation {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.tilt-animation {
  transform-style: preserve-3d;
}

.rotate-infinite {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.line-draw-animation {
  position: relative;
}

.line-draw-animation::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #ea110c;
  right: 0;
  top: 0;
  transition-delay: 0.2s;
}

.line-draw-animation::after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  transition: all 0.2s linear;
  background: #ea110c;
  left: 0;
  bottom: 0;
  transition-delay: 0.6s;
}

.line-draw-animation:hover::before {
  width: 100%;
  transition-delay: 0.4s;
}

.line-draw-animation:hover::after {
  width: 100%;
  transition-delay: 0s;
}

.line-draw-animation:hover .line-draw-inner::before {
  height: 100%;
  transition-delay: 0.6s;
}

.line-draw-animation:hover .line-draw-inner::after {
  height: 100%;
  transition-delay: 0.2s;
}

.line-draw-animation .line-draw-inner::after {
  transition-delay: 0.4s;
  right: 0;
  bottom: 0;
}

.line-draw-animation .line-draw-inner::before {
  transition-delay: 0s;
  left: 0;
  top: 0;
}

.line-draw-inner::before {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #ea110c;
}

.line-draw-inner::after {
  content: "";
  width: 2px;
  height: 0;
  position: absolute;
  transition: all 0.2s linear;
  background: #ea110c;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #28a745;
  fill: none;
  animation: checkmarkstroke 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 5% auto;
  box-shadow: inset 0px 0px 0px #28a745;
  animation: checkmarkfill .6s ease-in-out .9s forwards, checkmarkscale .8s ease-in-out 2s both;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: checkmarkstroke 1.1s cubic-bezier(0.65, 0, 0.45, 1) 1.2s forwards;
}

@keyframes checkmarkstroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmarkscale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.3, 1.3, 1);
  }
}

@keyframes checkmarkfill {
  100% {
    box-shadow: inset 0px 0px 0px 60px #28a745;
  }
}

.reveal-item {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.reveal-item .reveal-animation {
  position: absolute;
  top: 0;
  width: 100%;
  height: 101%;
  background: #f0f1f3;
}

.reveal-item .reveal-animation::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d0d4d9;
  transition-property: transform;
  transition-duration: 1s;
}

.reveal-animation.reveal-primary::before {
  background: #ea110c;
}

.reveal-animation.reveal-dark::before {
  background: #19191c;
}

.reveal-animation.reveal-white::before {
  background: #fff;
}

.reveal-animation.reveal-top.aos-animate::before,
.reveal-animation.reveal-bottom.aos-animate::before {
  transform: scaleY(1);
}

.reveal-animation.reveal-start.aos-animate::before,
.reveal-animation.reveal-end.aos-animate::before {
  transform: scaleX(1);
}

.reveal-animation.reveal-top::before {
  transform: scaleY(0);
  transform-origin: 0% 100%;
}

.reveal-animation.reveal-start::before {
  transform: scaleX(0);
  transform-origin: 100% 0%;
}

.reveal-animation.reveal-end::before {
  transform: scaleX(0);
  transform-origin: 0% 100%;
}

.reveal-animation.reveal-bottom::before {
  transform: scaleY(0);
  transform-origin: 100% 0%;
}

[data-aos="reveal-top"],
[data-aos="reveal-start"],
[data-aos="reveal-end"],
[data-aos="reveal-bottom"] {
  transition-property: transform;
  transition-delay: 1s;
}

[data-aos="reveal-top"] {
  transform: scaleY(1);
}

[data-aos="reveal-top"].aos-animate {
  transform: scaleY(0);
  transform-origin: 100% 0%;
}

[data-aos="reveal-start"] {
  transform: scaleX(1);
}

[data-aos="reveal-start"].aos-animate {
  transform: scaleX(0);
  transform-origin: 0% 100%;
}

[data-aos="reveal-end"] {
  transform: scaleX(1);
}

[data-aos="reveal-end"].aos-animate {
  transform: scaleX(0);
  transform-origin: 100% 0%;
}

[data-aos="reveal-bottom"] {
  transform: scaleY(1);
}

[data-aos="reveal-bottom"].aos-animate {
  transform: scaleY(0);
  transform-origin: 0% 100%;
}

[data-aos="reveal-item"] {
  visibility: hidden;
  transition-property: visibility;
  transition-duration: 0s;
}

[data-aos="reveal-item"].aos-animate {
  visibility: visible;
}

@media (min-width: 768px) {
  .col-md-full-right {
    position: absolute;
    width: 1000%;
    max-width: 50%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  footer.footer-sticky {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1 !important;
  }
}

.featured-label {
  position: absolute;
  z-index: 99;
  background: #ea110c;
  color: #fff;
  transform: rotate(45deg);
  text-align: center;
  top: -8px;
  right: -48px;
  width: 120px;
  padding: 16px 0 3px 0;
}

.preloader {
  background-color: #fff;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.preloader .preloader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block !important;
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-group {
  display: flex;
}

.avatar-group > li {
  position: relative;
}

.avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}

.avatar-group .avatar-img {
  border: 2px solid #fff;
}

.avatar-xs {
  height: 1.625rem;
  width: 1.625rem;
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
}

.avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}

.avatar-xxl {
  height: 5.125rem;
  width: 5.125rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxxl {
  height: 8rem;
  width: 8rem;
}

@media (min-width: 768px) {
  .avatar-xxxl {
    width: 11rem;
    height: 11rem;
  }
}

.divider {
  height: 1px;
  background: #000;
  display: block;
  width: 100%;
  margin: 0 auto;
}

.divider-light {
  height: 1px;
  background: #fff;
  display: block;
  width: 100%;
  margin: 0 auto;
}

.bg-dark-overlay-2 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-2:before {
  content: " ";
  background: #000;
  opacity: 0.2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-3 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-3:before {
  content: " ";
  background: #000;
  opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-4 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-4:before {
  content: " ";
  background: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-5 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-5:before {
  content: " ";
  background: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-6 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-6:before {
  content: " ";
  background: #000;
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-7 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-7:before {
  content: " ";
  background: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-8 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-8:before {
  content: " ";
  background: #000;
  opacity: 0.8;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-dark-overlay-9 {
  position: relative;
  z-index: 1;
}

.bg-dark-overlay-9:before {
  content: " ";
  background: #000;
  opacity: 0.9;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-light-overlay-dotted {
  background-image: radial-gradient(#a1a1a8 10%, transparent 0), radial-gradient(#a1a1a8 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

.bg-dark-overlay-dotted {
  background-image: radial-gradient(#19191c 10%, transparent 0), radial-gradient(#19191c 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

.bg-primary-overlay-dotted {
  background-image: radial-gradient(#ea110c 10%, transparent 0), radial-gradient(#ea110c 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

.bg-white-overlay-dotted {
  background-image: radial-gradient(#fff 10%, transparent 0), radial-gradient(#fff 10%, transparent 0);
  background-size: 7px 5px;
  background-position: 11px 13px, 0px 6px;
}

[class*='pattern-overlay-'] {
  position: relative;
  z-index: 1;
}

.pattern-overlay-1::after {
  content: "";
  background: url(../images/bg/pattern/01.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-2::after {
  content: "";
  background: url(../images/bg/pattern/02.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-3::after {
  content: "";
  background: url(../images/bg/pattern/03.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-4::after {
  content: "";
  background: url(../images/bg/pattern/04.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-5::after {
  content: "";
  background: url(../images/bg/pattern/05.png);
  background-position: center center;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-6::after {
  content: "";
  background: url(../images/bg/pattern/06.png);
  background-position: center center;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-7::after {
  content: "";
  background: url(../images/bg/pattern/07.png);
  background-position: center center;
  background-repeat: repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.pattern-overlay-8::after {
  content: "";
  background: url(../images/bg/pattern/08.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.tiny-slider [class*="animate__"] {
  animation-name: none;
}

.tiny-slider .tns-slide-active .animate__fadeInUp {
  animation-name: fadeInUp;
}

.tns-outer {
  position: relative;
}

.tiny-slider .item {
  position: relative;
}

.tns-controls [data-controls] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  display: block;
  z-index: 9;
  text-align: center;
  transition: all 0.3s ease-in-out;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: none;
  padding: 0;
  margin: 0 10px;
}

.tns-controls [data-controls]:hover {
  background-color: #ea110c;
  color: #fff;
}

.tns-controls [data-controls] i {
  line-height: inherit;
}

.tns-controls [data-controls='prev'] {
  left: 0;
}

.tns-controls [data-controls='next'] {
  right: 0;
}

/* rtl:raw:
.tiny-slider .tns-controls [data-controls]{
  transform: scaleX(-1) translateY(-50%);
}
*/
.arrow-dark [data-controls] {
  background: #19191c;
  color: #fff;
}

.arrow-gray [data-controls] {
  background: #dfe0e5;
  color: #19191c;
}

.arrow-round [data-controls] {
  border-radius: 50%;
}

.arrow-bordered [data-controls] {
  background: transparent !important;
  color: #d0d4d9;
  border: 2px solid #d0d4d9;
  box-sizing: initial;
}

.arrow-bordered [data-controls]:hover {
  border-color: #19191c;
  background: transparent;
  color: #19191c;
}

.arrow-only [data-controls] {
  background: transparent;
}

.arrow-only [data-controls]:hover {
  background: transparent;
  color: #19191c;
}

.arrow-hover {
  overflow: hidden;
}

.arrow-hover [data-controls='prev'] {
  left: -60px;
}

.arrow-hover [data-controls='next'] {
  right: -60px;
}

.arrow-hover:hover [data-controls='prev'] {
  left: 0px;
}

.arrow-hover:hover [data-controls='next'] {
  right: 0px;
}

@media (max-width: 767.98px) {
  .arrow-md-none [data-controls] {
    display: none;
  }
}

.arrow-start-bottom .tns-controls [data-controls] {
  top: auto;
  bottom: 10px;
}

.arrow-start-bottom [data-controls='prev'] {
  left: 20px;
}

.arrow-start-bottom [data-controls='next'] {
  left: 90px;
}

.tns-nav {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 40px 0 20px;
  z-index: 10;
  width: auto;
  text-align: center;
  white-space: nowrap;
}

.tns-nav [data-nav] {
  position: relative;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #a1a1a8;
  transition: all 0.3s ease-in-out;
  border: 0;
}

.tns-nav [data-nav]:after {
  border-radius: 50%;
  overflow: hidden;
  content: '';
  position: absolute;
  bottom: 0;
  height: 0;
  left: 0;
  width: 100%;
  background-color: #a1a1a8;
  box-shadow: 0 0 1px #a1a1a8;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}

.tns-nav .tns-nav-active:after {
  height: 100%;
}

.dots-inside .tns-nav {
  top: auto;
  padding-bottom: 20px;
}

.dots-end .tns-nav {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  padding: 0;
  right: 30px;
  left: auto;
}

.dots-end .tns-nav [data-nav] {
  display: block;
  margin: 6px;
}

.dots-primary .tns-nav [data-nav] {
  box-shadow: inset 0 0 0 2px #ea110c;
  transition: all 0.3s ease-in-out;
}

.dots-primary .tns-nav [data-nav]:after {
  background-color: #ea110c;
  box-shadow: 0 0 1px #ea110c;
}

.dots-white .tns-nav [data-nav] {
  box-shadow: inset 0 0 0 2px #fff;
  transition: all 0.3s ease-in-out;
}

.dots-white .tns-nav [data-nav]:after {
  background-color: #fff;
  box-shadow: 0 0 1px #fff;
}

.dots-dark .tns-nav [data-nav] {
  box-shadow: inset 0 0 0 2px #19191c;
  transition: all 0.3s ease-in-out;
}

.dots-dark .tns-nav [data-nav]:after {
  background-color: #19191c;
  box-shadow: 0 0 1px #19191c;
}

.dots-bordered .tns-nav [data-nav] {
  position: relative;
  height: 17px;
  width: 17px;
  box-shadow: inset 0 0 0 2px transparent;
}

.dots-bordered .tns-nav [data-nav]:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  height: 5px;
  width: 5px;
  background: #19191c;
  display: block;
}

.dots-bordered .tns-nav .tns-nav-active {
  box-shadow: inset 0 0 0 2px #19191c;
}

.dots-white.dots-bordered [data-nav]:after {
  background: #fff;
}

.dots-white.dots-bordered .tns-nav-active {
  box-shadow: inset 0 0 0 2px #fff;
}

.dots-start-bottom .tns-nav {
  left: 0;
  transform: none;
}

.mt-30 {
  margin-top: 1.875rem;
}

.mb-30 {
  margin-bottom: 1.875rem;
}

.z-index-0 {
  z-index: 0;
}

.z-index-9 {
  z-index: 9;
}

.z-index-99 {
  z-index: 99;
}

.z-index-n9 {
  z-index: -9;
}

@media (min-width: 992px) {
  .position-lg-absolute {
    position: absolute;
  }
}

.text-primary-hover a {
  color: #a1a1a8;
}

.text-primary-hover a:hover {
  color: #ea110c !important;
}

a.text-primary-hover, button.text-primary-hover {
  color: #d0d4d9;
}

a.text-primary-hover:hover, button.text-primary-hover:hover {
  color: #ea110c !important;
}

.text-primary-hover li {
  color: #d0d4d9;
}

.text-primary-hover li:hover {
  color: #ea110c !important;
}

.bg-primary-multiply {
  background-color: #ea110c;
  mix-blend-mode: multiply;
}

.bg-primary-multiply img {
  mix-blend-mode: multiply;
}

.bg-primary-multiply-hover {
  background-color: #ea110c;
  transition: all 0.3s ease-in-out;
}

.bg-primary-multiply-hover:hover img {
  mix-blend-mode: multiply;
  transition: all 0.3s ease-in-out;
}

.bg-primary-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #ea110c;
  z-index: -1;
}

.bg-white-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
}

.bg-dark-start:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  right: 0;
  bottom: 0;
  background: #19191c;
  z-index: -1;
}

.bg-primary-end:before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  left: 0;
  bottom: 0;
  background: #ea110c;
  z-index: -1;
}

.bg-white-end:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: #fff;
  z-index: -1;
}

.bg-dark-end:before {
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: #19191c;
  z-index: -1;
}

.font-base {
  font-family: "Rubik", sans-serif !important;
}

.font-heading {
  font-family: "Spartan", sans-serif !important;
}

.font-alt {
  font-family: "Reenie Beanie", cursive !important;
}

.grayscale {
  filter: grayscale(100%);
  transition: all .6s ease;
}

.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.zoom-hover {
  transform: scale(1);
}

.zoom-hover:hover {
  transform: scale(1.2);
}

.up-hover {
  transition: all 0.3s ease-in-out;
}

.up-hover:hover {
  transform: translateY(-10px);
}

.zoom-1 {
  transform: scale(1.1);
}

.zoom-2 {
  transform: scale(1.3);
}

.zoom-3 {
  transform: scale(1.5);
}

.zoom-4 {
  transform: scale(2);
}

.zoom-n1 {
  transform: scale(0.9);
}

.zoom-n2 {
  transform: scale(0.6);
}

.zoom-n3 {
  transform: scale(0.4);
}

.zoom-n4 {
  transform: scale(0.2);
}

.blur-1 {
  filter: blur(2px);
}

.blur-2 {
  filter: blur(5px);
}

.blur-3 {
  filter: blur(10px);
}

.rotate-10 {
  transform: rotate(-10deg);
}

.rotate-45 {
  transform: rotate(-45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.blink-animation {
  transition: all 0.3s ease-in-out;
  animation: blink-animation 1s steps(5, start) infinite;
  visibility: visible;
}

.fill-none {
  fill: none;
}

.fill-primary {
  fill: #ea110c;
}

.fill-primary-light {
  fill: #ee413d;
}

.fill-primary-dark {
  fill: #ce0f0b;
}

.fill-white {
  fill: #fff;
}

.fill-light {
  fill: #f7f8f9;
}

.fill-dark {
  fill: #19191c;
}

.stroke-primary {
  stroke: #ea110c;
}

.stroke-10 {
  stroke-miterlimit: 10;
}

.svg-custom-border-dark {
  fill: none;
  stroke: #19191c;
  stroke-width: 1.1786;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.svg-custom-border-white {
  fill: none;
  stroke: #fff;
  stroke-width: 1.1786;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.svg-custom-border-primary {
  fill: none;
  stroke: #ea110c;
  stroke-width: 1.1786;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.svg-custom-border-2 {
  fill: none;
  stroke: #19191c;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

/*User CSS*/
